import React, { useEffect, useState } from 'react';
import './organisations.scss'; 
import { GiMagicHat } from "react-icons/gi";
import { TfiApple } from "react-icons/tfi";
import { TbCat } from "react-icons/tb";
import { SiWeblate } from "react-icons/si";
import { SiSimilarweb } from "react-icons/si";
import { useNavigate } from 'react-router-dom'; 
import {useGetUserOrganisationsQuery } from "../../features/users/usersApiSlice";


const apps = [
  { title: 'Magic', description: 'A modern and clean design system for developing fast and powerful web interfaces.', color: '#3B82F6', icon: <GiMagicHat />, route: '/sitemap/1/' },
  { title: 'Barry', description: 'A modern and clean design system for developing fast and powerful web interfaces.', color: '#111827', icon: <TfiApple />, route: '/sitemap/1/' },
  { title: 'BlueCat', description: 'A modern and clean design system for developing fast and powerful web interfaces.', color: '#06B6D4', icon: <TbCat />, route: '/sitemap/1/' },
  { title: 'Tres', description: 'A modern and clean design system for developing fast and powerful web interfaces.', color: '#8B5CF6', icon: <SiWeblate />, route: '/sitemap/1/' },
  { title: 'Gotcha', description: 'A modern and clean design system for developing fast and powerful web interfaces.', color: '#EF4444', icon: <SiSimilarweb />, route: '/sitemap/1/' },
];

const Organisations = () => {
  const [organisations, setOrganisations] = useState([])
  const navigate = useNavigate(); // Initialize useNavigate

  const {
    data: organisation_members,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetUserOrganisationsQuery();

  const handleCardClick = (organisation_id) => {
    navigate(`/sitemap/${organisation_id}/`); // Navigate to the specified route
  };
  useEffect(()=>{
    refetch()
  },[])

  // Separate the apps into two arrays for left and right columns

  return (
    <div className="organisations-container">
      <h2>Your Organisations</h2>
      <div className="apps-grid grid">
          {organisation_members && organisation_members.data.map((app, index) => (
            <div 
              key={index}
              className="app-card"
              data-color={app.color}
              onClick={() => handleCardClick(app.organisation_id)} // Add onClick handler
            >
              <div 
                className="app-icon"
                style={{ backgroundColor: app.color }}
              >
                <img src={app.logo}/>
              </div>
              <div className="app-details">
                <h3>{app.name_nn}</h3>
                <p>{app.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Organisations;
