import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useInviteMemberToLocationMutation } from '../../features/users/usersApiSlice';
import { MemberInvitedPopup } from '../../components/popups/MemberInvited';
import './inviteMember.scss';
import { FaMapMarkerAlt, FaUser } from 'react-icons/fa'; // Import icons

const InviteMemberForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMemberInvitedPopup, setShowMemberInvitedPopup] = useState(false);
  const { organisationId, itemId, groupId } = location.state || {};
  const [shareLink, setShareLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adminAccess, setAdminAccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const [inviteMemberToLocation, { isLoading: createInviteMemberToLocationIsLoading }] =
    useInviteMemberToLocationMutation();

  const handleInvite = async (e) => {
    e.preventDefault();

    if (!isFormValid) return;

    const data = {
      is_admin_access_ind: adminAccess,
      location: itemId,
      organisation: organisationId,
    };
    if (email) {
      data.email = email;
    } else {
      data.phone = phone;
    }

    try {
      const response = await inviteMemberToLocation(data).unwrap();
      
      if (response.data) {
        setShareLink(response.data.link);
        setShowMemberInvitedPopup(true);
      }
    } catch (error) {
      setErrorMessage(error?.data?.message || "An error occurred. Please try again.");
      setShowMemberInvitedPopup(true);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if at least one field is filled
    if (!email && !phone) {
        // Set isFormValid to false, but don't add a message to newErrors
        setIsFormValid(false);
        return;
    }

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        newErrors.email = 'Please enter a valid email address.';
    }

    if (phone && !/^\+91[6-9]\d{9}$/i.test(phone)) {
        newErrors.phone = 'Phone number must start with +91 and have exactly 10 digits.';
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
};


  const onMemberInvitedPopupClose = () => {
    setShowMemberInvitedPopup(false);
    setErrorMessage(''); // Reset error message
    setShareLink(''); // Reset share link
    navigate(-1);
  };

  useEffect(() => {
    validateForm();
  }, [email, phone]);

  useEffect(() => {
    if (!organisationId || !itemId || !groupId) {
      navigate(-1);
    }
  }, [organisationId, itemId, groupId, navigate]);

  return (
    <div className="invite-member-form">
      <h2>Invite Member to Chat Group</h2>
      <p>Connect and Collaborate Instantly</p>

      <h4 className="personal-info-header">
        <FaUser style={{ color: '#32A0EF' }} /> Personal Information
      </h4>
      <Form onSubmit={handleInvite} className="form-invite-member">
        <Row className="row-info-location">
          <Col className="col-margin">
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email Id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <div className="error-text">{errors.email}</div>}
            </Form.Group>
          </Col>
          <Col className="col-margin">
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter phone number (+91)"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {errors.phone && <div className="error-text">{errors.phone}</div>}
            </Form.Group>
          </Col>
        </Row>

        {errors.general && <div className="error-text">{errors.general}</div>}

        <Form.Group controlId="adminAccess" className="mt-3">
          <Form.Check
            type="checkbox"
            label="Grant Admin Access"
            checked={adminAccess}
            onChange={(e) => setAdminAccess(e.target.checked)}
          />
        </Form.Group>

        <div className="button-group mt-4">
          <Button variant="secondary" type="button" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="ml-2"
            disabled={!isFormValid}
          >
            Invite
          </Button>
        </div>
      </Form>

      <MemberInvitedPopup
        link={shareLink}
        errorMessage={errorMessage}
        show={showMemberInvitedPopup}
        onClose={onMemberInvitedPopupClose}
      />
    </div>
  );
};

export default InviteMemberForm;
