import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({ url: "/accounts/get_user_data/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getOrganisationMembers: builder.query({
      query: ({ search, sort_by }) => {
        let url = "/organisation/organisation_members/?";
        if (search !== "") {
          url += `search_keyword=${search}&`;
        }
        // console.log(sort_by, "asg");
        if (sort_by !== "" && sort_by !== undefined) {
          url += `sort_by=${sort_by}&`;
        }
        return { url: url, method: "get" };
      },
      keepUnusedDataFor: 0,
    }),
    getFailedUploadMembers: builder.query({
      query: () => ({
        url: "/organisation/failed_member_uploads/",
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getDashboardData: builder.query({
      query: () => ({ url: "/organisation/dashboard_data/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getUserProfile: builder.query({
      query: () => ({ url: "/accounts/profile/", method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getOrganisationLocations: builder.query({
      query: ({ parent_id }) => {
        if (parent_id !== null) {
          return {
            url: `/organisation/locations/?parent_id=${parent_id}/`,
            method: "get",
          };
        } else {
          return { url: `/organisation/locations/`, method: "get" };
        }
      },
      keepUnusedDataFor: 0,
    }),
    getOrganisationLocationBranches: builder.query({
      query: ({ parent_id }) => ({
        url: `/organisation/location_branches/${parent_id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getChannelDetails: builder.query({
      query: ({ group_id }) => ({
        url: `/organisation/group_details/${group_id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getUserDetails: builder.query({
      query: ({ user_id }) => ({
        url: `/accounts/get_user_data/${user_id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getMyDocuments: builder.query({
      query: () => ({ url: `/accounts/documents/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getAttendanceEmployees: builder.query({
      query: () => ({ url: `/attendance/employee_list/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getAttendanceEmployeesHistory: builder.query({
      query: (id) => ({
        url: `/attendance/employee_attendance_history/${id}/`,
        method: "get",
      }),
      keepUnusedDataFor: 0,
    }),
    getDocumentCategory: builder.query({
      query: () => ({ url: `/accounts/document_categories/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    // getOrganisationBroadcastHistory: builder.query({
    //   query: () => `/organisation/get_branch_broadcast_history/`,
    //   keepUnusedDataFor: 0
    // }),
    getCalendarEvents: builder.query({
      query: ({ month, year }) => ({ url: `/calender/calendar_list/?month=${month}&year=${year}`, method: 'get' }),
      keepUnusedDataFor: 0
    }),
    getPlugins: builder.query({
      query: () => ({ url: `/plugins/plugins/`, method: 'get' }),
      keepUnusedDataFor: 0
    }),
    updatePlugin: builder.mutation({
      query: (data) => ({
        url: "/plugins/plugins/", // Replace with your API endpoint for creating users
        method: "PUT",
        data, // You should pass your FormData here
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: "/accounts/profile/", // Replace with your API endpoint for creating users
        method: "PUT",
        data, // You should pass your FormData here
      }),
    }),
    createCalendarEvent: builder.mutation({
      query: (data) => ({
        url: "/calendar_manager/calendarevents/",
        method: "POST",
        data, // You should pass your FormData here
      }),
    }),
    deleteCalendarEvent: builder.mutation({
      query: (event_id) => ({
        url: `/calendar_manager/calendarevents/${event_id}/`, // Replace with your API endpoint for creating users
        method: "DELETE",
      }),
    }),
    updateOrganisations: builder.mutation({
      query: (userFormData) => ({
        url: "/accounts/update_organisation/", // Replace with your API endpoint for creating users
        method: "PUT",
        data: userFormData, // You should pass your FormData here
      }),
    }),
    removeParticipants: builder.mutation({
      query: (data) => ({
        url: "/organisation/participant/", // Replace with your API endpoint for creating users
        method: "DELETE",
        data, // You should pass your FormData here
      }),
    }),
    updateParticipantPermission: builder.mutation({
      query: (data) => ({
        url: "/organisation/update_permissions/", // Replace with your API endpoint for creating users
        method: "POST",
        data, // You should pass your FormData here
      }),
    }),
    createOrganisationMembers: builder.mutation({
      query: (userFormData) => ({
        url: "/accounts/create_members/", // Replace with your API endpoint for creating users
        method: "POST",
        data: userFormData, // You should pass your FormData here
      }),
    }),
    createUploadDocument: builder.mutation({
      query: (data) => ({
        url: "/accounts/document_upload/", // Replace with your API endpoint for creating users
        method: "POST",
        data, // You should pass your FormData here
      }),
    }),
    deleteDocument: builder.mutation({
      query: (document_id) => ({
        url: `/accounts/document_delete/${document_id}/`, // Replace with your API endpoint for creating users
        method: "DELETE",
      }),
    }),
    createLocation: builder.mutation({
      query: (data) => ({
        url: "/organisation/locations/", // Replace with your API endpoint for creating users
        method: "POST",
        data, // You should pass your FormData here
      }),
    }),
    sendBroadcast: builder.mutation({
      query: (data) => ({
        url: "/organisation/send_broadcast/", // Replace with your API endpoint for creating users
        method: "POST",
        data, // You should pass your FormData here
      }),
    }),
    deleteLocation: builder.mutation({
      query: (data) => ({
        url: "/organisation/locations/", // Replace with your API endpoint for creating users
        method: "DELETE",
        data, // You should pass your FormData here
      }),
    }),
    createBranch: builder.mutation({
      query: (data) => ({
        url: "/organisation/branch/", // Replace with your API endpoint for creating users
        method: "POST",
        data, // You should pass your FormData here
      }),
    }),
    getOrganisationBranch: builder.query({
      query: () => ({ url: `/organisation/branch/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    updateChannelMessage: builder.mutation({
      query: (data) => ({
        url: "/organisation/update_group_message/", // Replace with your API endpoint for creating users
        method: "POST",
        data,
      }),
    }),
    deleteBranch: builder.mutation({
      query: (data) => ({
        url: "/organisation/branch/", // Replace with your API endpoint for creating users
        method: "DELETE",
        data, // You should pass your FormData here
      }),
    }),
    // getUsersTemplate: builder.query({
    //   query: () => ({
    //     url: "/organisation/get_user_excel/",
    //     // responseType: 'arraybuffer',
    //     responseHandler: (response) => {
    //       return response
    //     },
    //   }),
    //   keepUnusedDataFor: 0,
    // }),
    getUserOrganisations: builder.query({
      query: () => ({ url: `/chats/user_organisation_list/`, method: "get" }),
      keepUnusedDataFor: 0,
    }),
    getOrganisationSitemap: builder.query({
      query: (organisation_id) => {
        return { url: `/chats/hierarchy/${organisation_id}/`, method: "get" };
      },
      keepUnusedDataFor: 0,
    }),
    getOrganisationSitemapByLocation: builder.query({
      query: ({ organisation_id, location_id }) => {
        // console.log("Sitemap", organisation_id, location_id);

        return {
          url: `/chats/hierarchy/${organisation_id}/${location_id}/`,
          method: "get",
        };
      },
      keepUnusedDataFor: 0,
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/accounts/deactivate-user/", // Replace with your API endpoint for creating users
        method: "POST",
      }),
    }),
    inviteMemberToLocation: builder.mutation({
      query: (data) => ({
        url: "/chats/sendchatlink/", // Replace with your API endpoint for creating users
        method: "POST",
        data,
      }),
    }),
    // Create Event
    createEvent: builder.mutation({
      query: (eventData) => ({
        url: "eventmanagement/eventscreate/",
        method: "POST",
        data: eventData,
        formData: true,
      }),
    }),
    // Fetch Events
    fetchEvents: builder.query({
      query: (page = 1) => `eventmanagement/eventscreate/?page=${page}`,
    }),

    // Update Event by ID
    updateEvent: builder.mutation({
      query: ({ eventId, eventData }) => ({
        url: `eventmanagement/eventscreate/${eventId}/`,
        method: "PUT",
        body: eventData,
        formData: true,
      }),
    }),
    createAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/create_announcement/",
        method: "POST",
        data
      }),
    }),

    updateAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/update_announcement/", // Replace with your API endpoint for creating users
        method: "PUT",
        data, // You should pass your FormData here
      }),
    }),

    feedbackAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/announcement_feedback/",
        method: "POST",
        data
      }),
    }),

    readAnnouncement: builder.mutation({
      query: (data) => ({
        url: "/announcement/announcement_read/",
        method: "POST",
        data
      }),
    }),

    getAnnouncementDetails: builder.query({
      query: (announcement_id) => ({
        url: `/announcement/announcement_detail/${announcement_id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getCreateFcmToken: builder.query({
      query: ({ device_id, token }) => ({
        url: `/announcement/get_create_fcm_token/`,
        method: 'GET',
        params: {
          device_id,
          token,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getAnnouncementList: builder.query({
      query: () => ({
        url: '/announcement/announcement_list/',
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getFeedbackDetails: builder.query({
      query: (feedback_id) => ({
        url: `/announcement/get_feedbackdetails/${feedback_id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    getMembersList: builder.query({
      query: ({organisation_id, keyword}) => ({
        url: `/members/${organisation_id}/${keyword}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),




  }),
});

export const {
  useGetUsersQuery,
  useGetOrganisationLocationsQuery,
  useGetOrganisationMembersQuery,
  useGetUserProfileQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useDeleteBranchMutation,
  useRemoveParticipantsMutation,
  useDeleteDocumentMutation,
  useUpdateParticipantPermissionMutation,
  useGetChannelDetailsQuery,
  useUpdateOrganisationsMutation,
  useCreateUploadDocumentMutation,
  useGetDocumentCategoryQuery,
  useGetMyDocumentsQuery,
  useCreateOrganisationMembersMutation,
  useCreateBranchMutation,
  useGetOrganisationLocationBranchesQuery,
  // useGetUsersTemplateQuery,
  useSendBroadcastMutation,
  useGetCalendarEventsQuery,
  useCreateCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useGetFailedUploadMembersQuery,
  useGetOrganisationBranchQuery,
  useUpdateChannelMessageMutation,
  useDeleteAccountMutation,
  useGetUserDetailsQuery,
  useGetDashboardDataQuery,
  useGetPluginsQuery,
  useUpdatePluginMutation,
  useGetAttendanceEmployeesQuery,
  useGetAttendanceEmployeesHistoryQuery,
  useUpdateUserProfileMutation,
  useGetUserOrganisationsQuery,
  useGetOrganisationSitemapQuery,
  useGetOrganisationSitemapByLocationQuery,
  useLazyGetOrganisationSitemapByLocationQuery,
  useInviteMemberToLocationMutation,
  useCreateAnnouncementMutation,
  useFeedbackAnnouncementMutation,
  useReadAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useGetAnnouncementDetailsQuery,
  useGetCreateFcmTokenQuery,
  useGetAnnouncementListQuery,
  useGetFeedbackDetailsQuery,
  useCreateEventMutation,
  useFetchEventsQuery,
  useUpdateEventMutation,
  useLazyGetMembersListQuery,
} = usersApiSlice;
