import React, { useEffect, useState } from "react";
import moment from 'moment';
import { useGetCalendarEventsQuery} from "../../features/users/usersApiSlice";
import CalendarHeader from "./CalendarHeader";
import DayView from "./DayView";
import WeekView from "./WeekView";
import MonthView from "./MonthView";
import Filter from "./Filter";
import RightSidebarCalendar from "./RightCalendar";
import './calendar.scss';

const Calendar = () => {
    const [currentView, setCurrentView] = useState("day");
    const [calendarData, setCalendarData] = useState({
        meetings: [],
        events: [],
        announcements: []
    })
    const [filters, setFilters] = useState({
        meetings: true,
        events: true,
        announcements: true,
    });
    const [selectedDate, setSelectedDate] = useState(moment());

    const {
        data: calendar_events,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
        error: error,
        refetch,
    } = useGetCalendarEventsQuery({month: 10, year: 2024});

    // const calendarData = {
    //     meetings: [
    //         { id: 1, title: "Design Review", time: "9:00 - 9:30", date: "2024-10-22" },
    //         { id: 2, title: "Sprint Meeting", time: "9:30 - 11:00", date: "2024-10-23" },
    //     ],
    //     events: [
    //         { id: 1, title: "Website Review", time: "11:00 - 11:30", date: "2024-10-22" },
    //     ],
    //     announcements: [
    //         { id: 1, title: "Team Meeting", time: "9:00 - 10:00", date: "2024-10-22" },
    //     ],
    // };

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };
    const changeDate = (amount) => {
        if (currentView === 'day') {
          // Change by days
          setSelectedDate(moment(selectedDate).add(amount, 'days'));
        } else if (currentView === 'week') {
          // Change by weeks
          setSelectedDate(moment(selectedDate).add(amount, 'weeks'));
        } else if (currentView === 'month') {
          // Change by months
          setSelectedDate(moment(selectedDate).add(amount, 'months'));
        }
      };
    
    useEffect(() => {
        console.log(calendar_events);
        if (isSuccess) {
            const filteredData = {
                meetings: [],
                events: [],
                announcements: []
            }

            calendar_events.data.activities.forEach(event => {
                if (event.type === "meeting") {
                    filteredData.meetings.push(event)
                } else if (event.type === "event") {
                    filteredData.events.push(event)
                } else if (event.schedule_type === "announcements") {
                    filteredData.announcements.push(event)
                }
            })
            setCalendarData(filteredData)
        }
    }, [calendar_events]);

    return (
        <div className="calendar-outer">
            <div className="calendar-container">
                <div className="calender-header-outer">
                <div>
                    {['week', 'month'].includes(currentView)? moment(selectedDate).format('MMMM, YYYY'): moment(selectedDate).format('MMMM Do, YYYY')}
                </div>

                <CalendarHeader
                    currentView={currentView}
                    onChangeView={handleViewChange}
                />
                <div className="calendar-navigation">
                {/* Add a button to change date (e.g., to previous or next day/week/month) */}
                
                <button onClick={() => changeDate(-1)}>{"<"}</button>
                <button onClick={() => setSelectedDate(moment())}>Today</button>
                <button onClick={() => changeDate(1)}>{">"}</button>
                </div>
                </div>
                {/* <Filter filters={filters} onChangeFilters={handleFilterChange} /> */}
                <div className="calendar-body">
                    {currentView === "day" && <DayView filters={filters} data={calendarData} selectedDate={selectedDate} />}
                    {currentView === "week" && <WeekView filters={filters} data={calendarData} selectedDate={selectedDate} />}
                    {currentView === "month" && <MonthView filters={filters} data={calendarData} selectedDate={selectedDate} />}
                </div>
            </div>
            <div className="right-sidebar">
                <RightSidebarCalendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    filters={filters}
                    onChangeFilters={handleFilterChange}
                />
            </div>

        </div>
    );
};

export default Calendar;
