import { React, useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye icons
import { store } from '../../app/store';
import { logOut } from '../../features/auth/authSlice';
import { TailSpin } from 'react-loader-spinner';
import { setCredentials } from '../../features/auth/authSlice';
import LoginLeftBg from '../../assets/img/login-left-bg.png'
import Logo from '../../assets/img/logo.png'
import LogoBlue from '../../assets/img/logo-blue.png'
import './styles.scss';

const Login = () => {
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPwd, setShowPwd] = useState(false); // State for password visibility
    const navigate = useNavigate();

    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        store.dispatch(logOut());
        userRef.current.focus();
    }, []);

    const toggleCheck = () => {
        setCheck(!check);
    };

    const toggleShowPwd = () => {
        setShowPwd(!showPwd); // Toggle password visibility
    };

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(user)) {
            setErrMsg('Invalid email format');
            return;
        }

        try {
            setLoading(true);
            store.dispatch(logOut());
            const userData = await login({ email: user, password: pwd }).unwrap();
            setLoading(false);
            console.log("userData: ", userData.data);

            dispatch(setCredentials({ ...userData.data, user }));
            setUser('');
            setPwd('');
            navigate('/home');
        } catch (err) {
            setLoading(false);
            if (!err?.originalStatus) {
                setErrMsg(err.data.data);
            } else if (err.originalStatus === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.originalStatus === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    };

    const handleUserInput = (e) => setUser(e.target.value);
    const handlePwdInput = (e) => setPwd(e.target.value);

    return (
        <section>
            <div className='login-outer'>
                <div className='login-inner'>
                    <div className='login-inner-left'>
                        <img src={LoginLeftBg} style={{ width: '80%', overflow: 'hidden', margin: "auto" }} />
                        <img className="left-logo" src={Logo} />
                    </div>
                    <div className='login-inner-right'>
                        <div className='login-sub-inner-right'>
                            <div className='form-logo-outer'>
                                <img className="form-logo" src={LogoBlue} />
                            </div>
                            <p style={{ color: 'red' }} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                            <div className='login-heading'>Login</div>
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <label htmlFor="email">Email</label>
                                <div className="wrapper">
                                    <div className="icon">
                                        <MdOutlineEmail />
                                    </div>
                                    <input
                                        type="text"
                                        id="username"
                                        className="input"
                                        ref={userRef}
                                        value={user}
                                        placeholder='Email'
                                        onChange={handleUserInput}
                                        autoComplete="new-password"
                                        required
                                    />
                                </div>
                                <label htmlFor="password">Password</label>
                                <div className="wrapper">
                                    <div className="icon">
                                        <CiLock />
                                    </div>
                                    <input
                                        className="input"
                                        type={showPwd ? "text" : "password"} // Toggle between text and password
                                        id="password"
                                        placeholder='Password'
                                        autoComplete="new-password"
                                        onChange={handlePwdInput}
                                        value={pwd}
                                        required
                                    />
                                    <div className="toggle-icon" onClick={toggleShowPwd}>
                                        {showPwd ? <AiOutlineEye /> : <AiOutlineEyeInvisible />} {/* Change icon based on state */}
                                    </div>
                                </div>
                                <button style={check && !loading ? {} : { background: 'grey' }} className='signin-btn'>
                                    {loading && <span style={{ marginRight: "10px" }}><TailSpin
                                        visible={true}
                                        height="30"
                                        width="30"
                                        color="#03a9f4"
                                        ariaLabel="tail-spin-loading"
                                        radius="2"
                                    />
                                    </span>}
                                    <span>
                                        Login
                                    </span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;
