export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return formattedDate + ' ' + formattedTime;
}

export const convertBytesToAuto = (bytes) => {
    if (bytes >= 1024 * 1024) {
        const megabytes = (bytes / (1024 * 1024)).toFixed(2);
        return `${megabytes} MB`;
    } else if (bytes >= 1024) {
        const kilobytes = (bytes / 1024).toFixed(2);
        return `${kilobytes} KB`;
    } else {
        return `${bytes} bytes`;
    }
}

export const UTCToLocalDate = (date) => {
    var theDate = new Date(Date.parse(date));
    return theDate.toDateString();
}

export const excelDateToJSDate = (excelDate) => {
    console.log(excelDate, "asfa")
    // Excel date epoch starts from December 30, 1899
    const excelEpoch = new Date(1899, 11, 30);

    // Calculate the milliseconds from the excel epoch to the specified date
    const milliseconds = excelDate * 24 * 60 * 60 * 1000;

    // Create a new Date object by adding the milliseconds to the excel epoch
    const jsDate = new Date(excelEpoch.getTime() + milliseconds);

    return jsDate;
}

export const formatDateToYYYYMMDD = (jsDate) => {
    const year = jsDate.getFullYear();
    const month = String(jsDate.getMonth() + 1).padStart(2, '0');
    const day = String(jsDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}



export const yearList = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1800;
    const years = [];
  
    for (let year = currentYear; year >= startYear; year--) {
      years.push({
        label: year.toString(),
        value: year,
      });
    }
    return years
}

