import React from 'react';
import moment from 'moment';
import './monthview.scss';

const MonthView = ({ filters, data, selectedDate }) => {
  const startOfMonth = moment(selectedDate).startOf('month');
  const endOfMonth = moment(selectedDate).endOf('month');

  const filteredData = (type) => {
    return data[type].filter(item =>
      moment(item.date).isBetween(startOfMonth, endOfMonth, null, '[]')
    );
  };

  const daysInMonth = Array.from(
    { length: endOfMonth.date() },
    (_, i) => moment(startOfMonth).add(i, 'days')
  );

  return (
    <div className="month-view">
      {/* <h2>Month View - {startOfMonth.format('MMMM YYYY')}</h2> */}
      <div className="month-grid">
        {daysInMonth.map(day => (
          <div key={day} className="day-cell">
            <h3>{day.format('D')}</h3>

            {filters.meetings &&
              filteredData('meetings')
                .filter(meeting => moment(meeting.date).isSame(day, 'day'))
                .map(meeting => (
                  <div className="event-item meeting-data-item" key={meeting.calendar_id}>
                    <p>{meeting.title}</p>
                    <span>{meeting.time}</span>
                  </div>
                ))
            }

            {filters.events &&
              filteredData('events')
                .filter(event => moment(event.date).isSame(day, 'day'))
                .map(event => (
                  <div className="event-item event-data-item" key={event.calendar_id}>
                    <p>{event.title}</p>
                    <span>{event.time}</span>
                  </div>
                ))
            }

            {filters.announcements &&
              filteredData('announcements')
                .filter(announcement => moment(announcement.date).isSame(day, 'day'))
                .map(announcement => (
                  <div className="event-item announcement-data-item" key={announcement.calendar_id}>
                    <p>{announcement.announcement_fk.title}</p>
                    <span>{new Date(announcement.announcement_fk.scheduled_time).toLocaleTimeString()}</span>
                  </div>
                ))
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthView;
