import { useNavigate, Link } from "react-router-dom";
import { React, useState, useContext, useRef, useEffect } from "react";

import { FaQuoteLeft } from "react-icons/fa6";
import Frame from '../../../src/assets/img/Frame.png'

import { BsDot } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Authentication from '../../../src/assets/img/Authentication.png'
import { LuDownload } from "react-icons/lu";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { IoMdStar } from "react-icons/io";
import { BsStars } from "react-icons/bs";
import Group from '../../../src/assets/img/Group 626488.png';
import dashbaord from '../../../src/assets/img/Dashboard leave balances ui (3).jpg';
import Employee from '../../../src/assets/img/Employee Profile Page (1).png';
import Leave from '../../../src/assets/img/Leave Apply (1).png';
import Admin from '../../../src/assets/img/Image (3).png';
import Image from '../../../src/assets/img/Image (4).png';
import Mobile from "../../../src/assets/img/Image 3.png";
import MobileApplication from "../../../src/assets/img/Image 4.png";
import Profile from "../../../src/assets/img/Image 5.png";
import Notification from "../../../src/assets/img/Image 6.png";
import FrameImage from "../../../src/assets/img/Frame 627584 (1).png";
import Circle from "../../../src/assets/img/mdi_tick-circle.png";
import Features from "../../../src/assets/img/Features section.png";
import Avtar from "../../../src/assets/img/Frame 627417.png";

import './styles.scss';
const Landing = () => {

  const navigate = useNavigate();

  return (
    <section className="home-page-layout-inner">
      <main className="main-content-landing">
        <div className="section-1">
          <div className="header">
            <div className="team-control">
              <div className="avatars">
                <img src={Avtar} alt="Avatar" />

              </div>
              <span className="team-text">Seamless Team Control</span>
            </div>
            <div className="header-left">
              <h1>Welcome to the Antro Employee Management Portal</h1>
              <p>Effortlessly manage your team, anytime, anywhere.</p>
            </div>
          </div>

          <div className="images-container">
            <div className="image-item">
              <img src={Group} alt="Leave Balance UI" />

            </div>

          </div>
        </div>
        <div>
        </div>

        <div className="features-section">
          <div className="key-features-container">
            <span className="key-features-text">KEY FEATURES</span>
          </div>
          <div className="feature">
            <div className="feature-text">
              <h2>Antro Advantage: Empowering Seamless Efficiency</h2>
              <p>Experience the Antro Advantage: delivering seamless efficiency through cutting-edge features designed to empower your business.</p>
            </div>
            <div className="feature-details">
              <div className="feature-item">
                <img src={Admin} alt="Admin" />
                <h3>User Attendance Tracking</h3>
                <p>Keep track of your team's attendance with ease. View daily logs, monitor absences, and generate reports in just a few clicks. Ensure your workforce is on time and productive.</p>

              </div>
              <div className="feature-item">
                <img src={Image} alt="image" />
                <h3>Seamless Employee Onboarding</h3>
                <p>Onboard new employees directly through the portal. Import employee details from your organization's HR portal directly into the system, ensuring accurate and up-to-date records.</p>

              </div>
              <div className="feature-item">
                <img src={Mobile} alt="Admin" />
                <h3>Mobile QR Code Attendance</h3>
                <p>Simplify attendance recording with our real-time QR code system. Each employee has a unique, real-time updated QR code available on their mobile app, ensuring no duplicates. Employees can scan their code at the start of the day using our QR scanner, making attendance tracking faster and more reliable.</p>

              </div>

              <div className="feature-item">
                <img src={MobileApplication} alt="Admin" />
                <h3>Mobile Application Management</h3>
                <p>Manage your entire workforce on-the-go with our mobile app. Add or update employee profiles, monitor attendance, and make changes to team structure right from your mobile device.</p>
              </div>

              <div className="feature-item">
                <img src={Profile} alt="profile" />
                <h3>Comprehensive Employee Profiles</h3>
                <p>Create, view, and manage employee profiles effortlessly. Track personal details, job roles, leave history, and more. Keep all your employee data up-to-date in one place.</p>
              </div>

              <div className="feature-item">
                <img src={Notification} alt="notification" />
                <h3>Real-Time Notifications</h3>
                <p>Stay informed with real-time alerts. Receive notifications for attendance discrepancies, onboarding status, and profile updates, ensuring you are always in the loop.</p>
              </div>

            </div>
          </div>
        </div>

        <div className="features-container">
          <div className="features-text">
            <div className="text-heading">WHAT SETS US APART

            </div>

            <div className="features-heading">
              <h2>
                WHY CHOOSE <strong>ANTRO?</strong>
              </h2>
              <p>Choose Antro for seamless, secure, and efficient workforce management.</p>
            </div>
          </div>
          <div className="features-image">
            <img src={FrameImage} alt="frame" />
          </div>

          <div className="features-list">
            <div className="feature-item">
              <img src={Circle} alt="profile" />
              <p>Efficient Management</p>
            </div>
            <div className="feature-item">
              <img src={Circle} alt="profile" />
              <p>HR Portal Integration</p>
            </div>
            <div className="feature-item">
              <img src={Circle} alt="profile" />
              <p>Real-Time QR Code Attendance</p>
            </div>
          </div>
          <div className="features-list">
            <div className="feature-item">
              <img src={Circle} alt="profile" />
              <p>Mobile Accessibility</p>
            </div>
            <div className="feature-item">
              <img src={Circle} alt="profile" />
              <p>Secure & Scalable</p>
            </div>
          </div>
        </div>
        <div className="help-section">
          <img src={Features} alt="features section" />
        </div>
      </main>
    </section>

  )
}

export default Landing
