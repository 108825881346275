import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import sitemapAvatar from "../../assets/img/sitemapAvatar.png";
import { HiDotsVertical } from "react-icons/hi";
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PiCalendarCheckFill } from "react-icons/pi";
import { HiSpeakerphone } from "react-icons/hi";
import { HiUserGroup } from "react-icons/hi2";
import { BsPersonFillGear } from "react-icons/bs";
import { AiFillMessage } from "react-icons/ai";
import { useGetOrganisationSitemapQuery, useLazyGetOrganisationSitemapByLocationQuery } from "../../features/users/usersApiSlice";
import "./sitemap.scss";


const TableRow = ({ item, id, index, organisationId }) => {
  const [expanded, setExpanded] = useState(false);
  const [locationDataFinal, setLocationDataFinal] = useState({})
  const [fetchedData, setFetchedData] = useState(false);
  const navigate = useNavigate();

  const [trigger, {
    data: location_data,
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useLazyGetOrganisationSitemapByLocationQuery();


  const handleRowClick = async () => {
    if (expanded) {
      setExpanded(false)
    } else {
      if (!fetchedData) {
        console.log("AMAR");
        const response = await trigger({organisation_id: organisationId, location_id: id});
    
        if (response.data && response.data.data && !response?.errors) {
          // console.log("ASFASF: ", response.data, response.errors);
          
          const updatedItemData = updateChildrenById(item, id, { expanded: !expanded });
          // setItemData(updatedItemData);
          setFetchedData(true);
          // setExpanded(true)
        }
      }
      setExpanded(true)
    }
  };
  useEffect(() => {
    console.log("location_data", location_data);
    if (isSuccess) {
      setLocationDataFinal(location_data.data);
    }
}, [location_data, isSuccess]);

  const handleDropdownSelect = (action, item) => {
    console.log("action", action, item);
    if (action === 'members') {
      navigate('/invite-member/',
        {
          state: { organisationId: organisationId, itemId: item.id, groupId: item.group_id }
        }
      );
    }
    else if (action === 'events') {
      navigate('/addevent/',
        {
          state: { organisationId: organisationId, itemId: item.id, groupId: item.group_id,  name: item.name }
        }
      );
    } else if (action === 'add-announcement') {
      navigate('/add_announcement/', {
          state: { itemId: item.id, name: item.name } 
      });
  }
  };
   

  const updateChildrenById = (data, id, updatedData) => {
    if (data.id === id) {
      
      return { ...data, ...updatedData };
    }

    if (data.children && data.children.length > 0) {
      return {
        ...data,
        children: data.children.map(child => updateChildrenById(child, id, updatedData))
      };
    }
    return data;
  }

  return (
    <>
      <tr style={{ cursor: 'pointer' }}>
        <td onClick={() => { handleRowClick(item.id) }}>{item.level < 5 ? <>{<span style={{ paddingLeft: `${20 * item.level}px` }}>{item.level < 4 ? expanded ? <FaCaretDown /> : <FaCaretRight onClick={() => { handleRowClick(item.id) }} /> : <></>}</span>}<BsGlobe className='globe-icon' /> {index + 1}. {`${item.name}`}</> : <></>}</td>
        {/* <td>{item.name}</td> */}
        <td onClick={() => { handleRowClick(item.id) }}><img src={sitemapAvatar} /><img src={sitemapAvatar} style={{ marginLeft: "-8px" }} /><img src={sitemapAvatar} style={{ marginLeft: "-8px" }} /> +{item.admins}</td>
        <td>{item.stats.members}</td>
        <td className='stats-outer-cell'>
          <div className='stats-outer-cell-tab'><PiCalendarCheckFill className='stats-outer-cell-tab-icon' /><span className='tab-count'>{item.stats.events}</span></div>
          <div className='stats-outer-cell-tab'><HiUserGroup className='stats-outer-cell-tab-icon' /><span className='tab-count'>{item.stats.members}</span></div>
          <div className='stats-outer-cell-tab'><HiSpeakerphone className='stats-outer-cell-tab-icon' /><span className='tab-count'>{item.stats.announcements}</span></div>
        </td>
        <td>
          <Dropdown autoClose="outside" onClick={(e) => e.stopPropagation()}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="no-caret" >
              <HiDotsVertical />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleDropdownSelect('messages', item)} href=""><span><AiFillMessage /></span>Messages</Dropdown.Item>
              <Dropdown.Item onClick={() => handleDropdownSelect('members', item)} href=""><span><HiUserGroup /></span>Add Member</Dropdown.Item>
              {item.level === "4" && <Dropdown.Item onClick={() => handleDropdownSelect('add-announcement', item)} href=""><span><HiSpeakerphone /></span>Add Announcement</Dropdown.Item>}
              {item.level === "4" && <Dropdown.Item onClick={()=> handleDropdownSelect('events',item)} href=""><span><PiCalendarCheckFill /></span>Add Event</Dropdown.Item>}
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleDropdownSelect('manage', item)} href=""><span><BsPersonFillGear /></span>Manage</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      {expanded && locationDataFinal.children && locationDataFinal.children.map((child, index) => {
        
        return <TableRow key={child.id} item={child} id={child.id} index={index} organisationId={organisationId} />
      })}
    </>
  );
};



const SiteMap = () => {
  const { organisationId } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const [sitemapDataFinal, setSitemapData] = useState({});

  const {
    data: sitemap_data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetOrganisationSitemapQuery(organisationId);

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const refetch_handler = async () => {
    const result = await refetch();
    setSitemapData(result.data.data);    
    console.log(" calling api in useeffect " + JSON.stringify(result.data.data)  + "  " +isSuccess)
    if (result.data) {
      // console.log(result.data, "sitemap datadfsdfdsgd");
        setSitemapData(result?.data?.data);
    }
  };
//   useEffect(() => {
//     if (isSuccess) {
//         setSitemapData(sitemap_data);
//     }
// }, [sitemap_data, isSuccess]);

  useEffect(() => {
    refetch_handler()
  }, [])


  return (
    <div className="sitemap-container">
      <table className="tree-table">
        <thead>
          <tr>
            <th className='level'>Type</th>
            <th >Admin</th>
            <th>Member</th>
            <th>Stats</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sitemapDataFinal && sitemapDataFinal?.children?.map((item, index) => (
            <TableRow key={item.id} item={item} id={item.id} index={index} organisationId={organisationId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SiteMap;
