import { Outlet, NavLink } from "react-router-dom"
import React from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import antrologo from '../../../assets/img/antro-logo.png'
import { SocialIcon } from 'react-social-icons';
import { FaGooglePlay } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import AntroLogoWhite from "../../../assets/img/antrologowhite.png";
import TwitterLogo from "../../../assets/img/mdi_twitter.png";
import InstagramLogo from "../../../assets/img/mdi_instagram.png";
import FacebookLogo from "../../../assets/img/ri_facebook-fill.png";
import LinkedInLogo from "../../../assets/img/ri_linkedin-fill.png";
import GoTop from "../../../assets/img/Frame 626816.png";
import { IoLocationOutline } from "react-icons/io5";

import './styles.scss'

const Layout1 = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This adds a smooth scrolling animation
        });
    };
    return (
        <main className="App">
            <Navbar className="outer-landing-navbar">
                <Container className="landing-navbar">
                    {/* <Navbar.Brand href="#home">
                       <img  className="logo-image" />
                    </Navbar.Brand> */}
                    <Navbar.Brand>
                        <img src={antrologo} className="logo-image" alt="Antro Logo" />
                    </Navbar.Brand>
                    <div>
                        <NavLink
                            to="/"
                            style={({ isActive }) => {
                                return isActive ? { color: "plum" } : {};
                            }}
                        >
                            {/* <img src={antrologo} /> */}
                            {/* Home */}
                        </NavLink>

                    </div>
                    <NavLink to="/login">
                        <button className="login-btn">Sign In</button>
                    </NavLink>
                </Container>
            </Navbar>
            <Outlet />
            <div className='main-container-footer'>

                <div className='footer-btnup'>
                    <div className="footer">
                        <div className="footer-content">
                            <div className="footer-logo">
                                <img src={AntroLogoWhite} alt="Antro Logo" />
                                <p>
                                    An innovative cybersecurity solutions company specializing in identity and access management.
                                    We offer real-time, process-driven solutions designed to protect against identity theft and impersonation.
                                </p>
                            </div>


                            {/* <div className="contact-info">
                                <h3>Contact Us</h3>
                                <p>Email Address: <a className='contact-email' href="mailto:connect@genoday.com">connect@genoday.com</a></p>
                                Location:
                                <p className='contact-address'>201, Ram Krishna Nivas Towers, Sanjeeva Reddy Nagar, Hyderabad, Telangana - 500038</p>
                            </div> */}

                            <div className="contact-info">
                                <h3>Contact Us</h3>
                                <p className="contact-item">
                                    <span className="contact-icon">&#9993;</span> {/* Envelope icon */}
                                    Email Address: <a className="contact-email" href="mailto:connect@genoday.com">connect@genoday.com</a>
                                </p>
                                <p className="contact-item">
                                    <span className="contact-icon"><IoLocationOutline /></span> 
                                    Location:
                                    <span className="contact-address">201, Ram Krishna Nivas Towers, Sanjeeva Reddy Nagar, Hyderabad, Telangana - 500038</span>
                                </p>
                            </div>


                            <div className="social-links">
                                <h3>Follow</h3>
                                <div className="icons">
                                    <a href="https://x.com/i/flow/login" target="_blank" rel="noopener noreferrer"><img src={TwitterLogo} alt="Twitter" /></a>
                                    <a href="https://www.facebook.com/login/" target="_blank" rel="noopener noreferrer"><img src={FacebookLogo} alt="Facebook" /></a>
                                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={InstagramLogo} alt="Instagram" /></a>
                                    <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><img src={LinkedInLogo} alt="LinkedIn" /></a>
                                </div>
                                <div className="subscribe">
                                    SUBSCRIBE NOW
                                </div>
                                <div className="newsletter-form">
                                    <input type="email" placeholder="Email Address" />
                                    <button>SUBSCRIBE
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom">
                            <p>Copyright 2023. All Rights Reserved.</p>
                            <div className="footer-links">
                                <a href="/terms">Terms & conditions</a>
                                <a href="/privacy-policy">Privacy policy</a>
                            </div>
                        </div>
                    </div>

                    {/* Go to Top Button */}
                    <div className='toggle-up-footer'  onClick={scrollToTop} >
                        <img src={GoTop} alt="Go to top" />
                    </div>
                </div>
            </div>

        </main>
    )
}

export default Layout1
