import React, { useState } from "react";
import { FaCalendarAlt, FaClock, FaGlobe, FaUser, FaPlus, FaFileImage } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import EditPencil from "../../assets/img/icons/edit_pencil.svg";
import EditCalendar from "../../assets/img/icons/events_calendar.svg";
import EditClock from "../../assets/img/icons/events_clock.svg";
import EditJpg from "../../assets/img/icons/events_jpg_icon.svg";
import EditJpeg from "../../assets/img/icons/events_jpeg_icon.svg";
import EditPng from "../../assets/img/icons/events_png_icon.svg";
import EditLocation from "../../assets/img/icons/location_events.svg";
import EditUpload from "../../assets/img/icons/upload_icon_events.svg";
import EditMember from "../../assets/img/icons/events_member.svg";
import "../Add Event/addevent.scss";
import { useCreateEventMutation, useLazyGetMembersListQuery } from "../../features/users/usersApiSlice";

const Addevent = () => {
  const { state } = useLocation();
  const [createEvent, { isLoading, error }] = useCreateEventMutation();
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    startDatetime: '',
    endDatetime: '',
    location: state.name,
    members: '',
    banner: null,
    attachments: [],
    scheduledDatetime: '',
  });
  const [expanded, setExpanded] = useState(false);
  const [fetchedData, setFetchedData] = useState(false)
  const [uploadedBanner, setUploadedBanner] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [trigger, {
    data: location_data,
    isLoading: getMembersIsLoading,
    isSuccess,
    isError,
    error: getMembersError,
  }] = useLazyGetMembersListQuery();

  const handleSearchMembers = async () => {
    if (expanded) {
      setExpanded(false)
    } else {
      if (!fetchedData) {
        console.log("AMAR");
        const response = await trigger({organisation_id: state.organisationId, location_id: state.id});
    
        if (response.data && response.data.data && !response?.errors) {
          // console.log("ASFASF: ", response.data, response.errors);
          
          // const updatedItemData = updateChildrenById(item, id, { expanded: !expanded });
          // setItemData(updatedItemData);
          setFetchedData(true);
          // setExpanded(true)
        }
      }
      setExpanded(true)
    }
  };


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'banner') {
      setEventData((prev) => ({ ...prev, banner: files }));
      setUploadedBanner([...files]);
    } else if (name === 'attachments') {
      setEventData((prev) => ({ ...prev, attachments: files }));
      setUploadedFiles([...files]);
    } else {
      setEventData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();

    switch (extension) {
      case "png":
        return EditPng; // Your imported PNG icon
      case "jpg":
      case "jpeg":
        return EditJpg; // Your imported JPG/JPEG icon
      default:
        return FaFileImage; // Default icon for unknown file types
    }
  };

  const handleCreateEvent = async () => {
    try {
      const formData = new FormData();
      formData.append("title", eventData.title);
      formData.append("description", eventData.description);
      formData.append("start_datetime_nn", eventData.startDatetime);
      formData.append("end_datetime_nn", eventData.endDatetime);
      formData.append("location_fk", eventData.location);
      formData.append("members_list", JSON.stringify(eventData.members.split(',')));
      if (eventData.banner) formData.append("banner", eventData.banner[0]);
      for (let attachment of eventData.attachments) formData.append("attachment_files", attachment);

      await createEvent(eventData).unwrap();
      setEventData({
        title: '',
        description: '',
        startDatetime: '',
        endDatetime: '',
        location: '',
        members: '',
        banner: null,
        attachments: [],
        scheduledDatetime: '',
      });
    } catch (error) {
      console.error("Failed to create event:", error);
    }
  };

  return (
    <div className="main-event-part">
      <div className="upper-event-part">
        <div className="event-name">Create Event</div>
        <div className="event-purpose">Fill in the Details to Launch Your Upcoming Event</div>
      </div>

      <div className="lower-event-part">
        <div className="event-input">
          <label>Title</label>
          <div className="input-with-icon">
            <img src={EditPencil} className="input-icon" />
            <input type="text" name="title" value={eventData.title} onChange={handleChange} placeholder="Enter event title" />
          </div>
        </div>

        <div className="event-input">
          <label>Description</label>
          <div className="description-editor">
            <div className="editor-content">
              <textarea name="description" value={eventData.description} onChange={handleChange} placeholder="Enter event details here..."></textarea>
            </div>
          </div>
        </div>

        <div className="end-start-event">
          <div className="event-input">
            <label>Event Starts</label>
            <div className="input-with-icons">
              <div className="date">
                <input type="date" name="startDatetime" value={eventData.startDatetime} onChange={handleChange} />
                <img src={EditCalendar} className="input-icon" />
              </div>
              <div className="time">
                <input type="time" name="startTime" onChange={handleChange} />
                <img src={EditClock} className="input-icon" />
              </div>
            </div>
          </div>

          <div className="event-input">
            <label>Event Ends</label>
            <div className="input-with-icons">
              <div className="date">
                <input type="date" name="endDatetime" value={eventData.endDatetime} onChange={handleChange} />
                <img src={EditCalendar} className="input-icon" />
              </div>
              <div className="time">
                <input type="time" name="endTime" onChange={handleChange} />
                <img src={EditClock} className="input-icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="event-input">
          <label>Location</label>
          <div className="input-with-icon">
            <img src={EditLocation} className="input-icon" />
            <input disabled type="text" name="location" value={eventData.location} onChange={handleChange} placeholder="Enter location" />
          </div>
        </div>

        <div className="event-input">
          <label>Members</label>
          <div className="input-with-icon">
            <img src={EditMember} className="input-icon" />
            <input type="text" name="members" value={eventData.members} onChange={handleChange} placeholder="Add members by name or email" />
          </div>
          <div className="members-images">
            <img src="member1.jpg" alt="member1" />
            <img src="member2.jpg" alt="member2" />
            <img src="member3.jpg" alt="member3" />
            <span className="member-count">+3</span>
          </div>
        </div>

        <div className="event-input">
          <label>Banner</label>
          <div className="upload-section">
            <img src={EditUpload} className="upload-icon" />
            <input type="file" name="banner" onChange={handleChange} />
            <div className="upload-content">
              <span className="upload-text">Upload an image</span> or click to browse (5 MB max)
            </div>
          </div>
          <div className="uploaded-files">
            {uploadedBanner.map((file, index) => (
              <div key={index} className="file-item">
                <img src={getFileIcon(file.name)} alt={file.name} />
                <div className="file-details">
                  <span>{file.name}</span>
                  <span>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="event-input">
          <label>Attachments</label>
          <div className="upload-section">
            <img src={EditUpload} className="upload-icon" />
            <input type="file" multiple name="attachments" onChange={handleChange} />
            <div className="upload-content">
              <span className="upload-text">Upload a file</span> or click to browse (5 MB max)
            </div>
          </div>
          <div className="uploaded-files">
            {uploadedFiles.map((file, index) => (
              <div key={index} className="file-item">
                <img src={getFileIcon(file.name)} alt={file.name} />
                <div className="file-details">
                  <span>{file.name}</span>
                  <span>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="event-input">
          <label>Scheduled Date & Time</label>
          <div className="input-with-icons">
            <div className="date">
              <input type="date" name="scheduledDatetime" value={eventData.scheduledDatetime} onChange={handleChange} />
              <img src={EditCalendar} className="input-icon" />
            </div>
            <div className="time">
              <input type="time" name="scheduledTime" onChange={handleChange} />
              <img src={EditClock} className="input-icon" />
            </div>
          </div>
        </div>

        <div className="event-actions">
          <button className="cancel-btn">Cancel</button>
          <button className="create-btn" onClick={handleCreateEvent} disabled={isLoading}>
            {isLoading ? "Creating..." : "Create"}
          </button>
        </div>

        {error && <p className="error-message">Failed to create event: {error.message}</p>}
      </div>
    </div>
  );
};

export default Addevent;
