import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../features/auth/authSlice";
import { useNavigate, Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { CgEnter } from "react-icons/cg";
import { Table } from "react-bootstrap";
import { useGetAttendanceEmployeesQuery } from "../../features/users/usersApiSlice";
import { React, useState, useContext, useRef, useEffect } from "react";
import "./styles.scss";


const Attendance = () => {

  const {
    data: employeesRefetchData,
    isLoading: employeesRefetchIsLoading,
    isSuccess: employeesRefetchIsSuccess,
    isError: employeesRefetchIsError,
    error: employeesRefetchError,
    refetch: employees_refetch,
  } = useGetAttendanceEmployeesQuery();


  const tableStyle = {
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
    // backgroundColor: "rgba(87, 166, 250, 0.0705882353)",
    margin: "10px"
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: 'none'
  };


  useEffect(() => {
    employees_refetch();
  }, []);


  return (
    <section className="layout-inner">
      <div className="attendance-container-outer">
        <div className="heading-outer">
          <div className="page-heading">Attendance </div>
          {/* <button className="upload-members-btn">Upload Members</button> */}
        </div>
        {employeesRefetchData &&<div className="attendance-outer">
          <Table className="table" style={tableStyle}>
            <thead style={tableHeaderStyle}>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {employeesRefetchData.data.map((candidate, index) => (
                <tr key={index} style={rowStyle}>
                  <td style={cellStyle}>{candidate.first_name}</td>
                  <td style={cellStyle}>{candidate.last_name}</td>
                  <td style={cellStyle}>{candidate.email}</td>
                  <td style={{...cellStyle, fontWeight: "bold"}}>
                    <div className="action-btns">
                      {candidate.user_id !== null && <Link to={`/attendance-history/${candidate.user_id}/`}>
                        <button className="action-btn">
                        <CgEnter />
                        </button>
                      </Link>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>}
      </div>
    </section>
  );
};

export default Attendance;
