import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../features/auth/authSlice";
import { Table } from "react-bootstrap";
import Select, { components, MenuListProps } from "react-select";
import { useGetOrganisationMembersQuery } from "../../features/users/usersApiSlice";
import { React, useState, useContext, useRef, useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import NewOrgansation from "../../components/NewOrgansation";
import { FailedUploadPopup } from "../../components/popups/FailedUploadPopup";
import { PluginsPopup } from "../../components/popups/PluginsPopup";
import  { UploadMemberPopup } from "../../components/popups/UploadMemberPopup";
import "./styles.scss";

// import './styles.scss'

const Members = () => {

  const [searchKeyword, setSearchKeyword] = useState("");
  const [showFailedUploadPopup, setShowFailedUploadPopup] = useState(false);
  const [showUploadMemberPopup, setShowUploadMemberPopup] = useState(false);
  const [showPluginPopup, setShowPluginPopup] = useState(false);
  const [sortBy, setSortBy] = useState({
    label: "Sort By",
    value: ""
  });
  const [sortByOptions, setSortByOptions] = useState([
    {
      label: "Sort By",
      value: ""
    },
    {
      label: "Name",
      value: "name"
    },
    {
      label: "Status",
      value: "status"
    }
  ]);
  const {
    data: members_data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetOrganisationMembersQuery(
    {
      search: searchKeyword,
      sort_by: sortBy.value
    }
  );

  const setSortHandler = (event) => {
    setSortBy(event);
    refetch()
  };

  const onFailedUploadPopupClose = () => {
    setShowFailedUploadPopup(false)
  }

  const onUploadMembePopupClose = () => {
    setShowUploadMemberPopup(false)
  }
  const onPluginPopupClose = () => {
    setShowPluginPopup(false)
  }

  const tableStyle = {
    // border: "1px solid #ccc",
    borderRadius: "8px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f8fafc",
    color: "white",
  };

  const rowStyle = {
    // backgroundColor: "rgba(87, 166, 250, 0.0705882353)",
    margin: "10px"
  };
  const cellStyle = {
    backgroundColor: "inherit",
    margin: "10px",
    border: 'none'
  };
  const handleSearchKeywordInput = (e) => {
    setSearchKeyword(e.target.value);
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();

  return (
    <section className="layout-inner">
      <div className="member-container-outer">
        <div className="member-container-inner">
        <div className="heading-outer">
          <div className="page-heading">Members list</div>
          <hr />
          <div className="plug-fail-btn">
            <button className="upload-members-btn upload-member-history-btn" onClick={()=> {setShowPluginPopup(true)}}>Plugins</button>
          </div>
        </div>
        {members_data && <div className="table-outer">
          <div className="table-functions">
            <div className="table-functions-dropdowns">
              <Select
                isSearchable={false}
                className="dropdown-item"
                value={sortBy}
                onChange={setSortHandler}
                options={sortByOptions}
              />
            </div>
            <div>
              <input
                className="search-input"
                placeholder="Search"
                onChange={handleSearchKeywordInput}
                value={searchKeyword}
              />
            </div>
          </div>
          <Table style={tableStyle}>
            <thead style={tableHeaderStyle}>
              <tr>
                <th></th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {members_data.data.map((candidate, index) => (
                <tr key={index} style={rowStyle}>
                  <td style={cellStyle}><input type="checkbox" /></td>
                  <td style={cellStyle}>{candidate.first_name}</td>
                  <td style={cellStyle}>{candidate.last_name}</td>
                  <td style={cellStyle}>{candidate.email}</td>
                  <td style={cellStyle}>{candidate.is_staff ? 'Admin' : 'Employee'}</td>
                  <td style={cellStyle}>
                    {
                      <div className="active-status-outer">
                        {candidate.upload_status === "completed" ?
                          <button className="active-status">Active</button> :
                          <button className="pending-status">Inactive</button>}
                      </div>
                    }
                  </td>
                  <td style={cellStyle}>
                    <div className="action-btns">
                      {candidate.user_id !== null && <Link to={`/member/${candidate.user_id}/`}>
                        <button className="action-btn" href="member/asf/">
                        <FaRegEdit />
                        </button>
                      </Link>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>}
        </div>
      </div>
      <UploadMemberPopup
        show={showUploadMemberPopup}
        onClose={onUploadMembePopupClose}
      />
      <FailedUploadPopup
        show={showFailedUploadPopup}
        onClose={onFailedUploadPopupClose}
      />
      <PluginsPopup
       show={showPluginPopup}
       onClose={onPluginPopupClose}
      />
    </section>
  );
};

export default Members;
