import { React, useEffect } from "react";
import Avatar from "../../../src/assets/img/avatar.jpg";
import DeleteIcon from "../../../src/assets/img/member-details/dustbin-icon.svg";
import BachelorIcon from "../../../src/assets/img/member-details/bachelordegree-icon.svg";
import MasterIcon from "../../../src/assets/img/member-details/masterdegree-icon.svg";
import GlobeIcon from "../../../src/assets/img/member-details/websiteicon.svg";
import CertificatesIcon from "../../../src/assets/img/member-details/certificates-icon.svg";
import EmployeeMonthIcon from "../../../src/assets/img/member-details/employee-month-icon.svg";
import TopInnovatorIcon from "../../../src/assets/img/member-details/top-innovator-icon.svg";
import CallIcon from "../../../src/assets/img/member-details/phone-icon.svg";
import MessageIcon from "../../../src/assets/img/member-details/message-icon.svg";
import NavigationArrowIcon from "../../../src/assets/img/member-details/navigation-arrow.svg";
import "./styles.scss";

const MemberDetails = () => {
  useEffect(() => {}, []);

  return (
    <section className="layout-inner-member-details">
      <div className="current-page">
        <div className="key">
          Members
          <span className="arrow-image">
            <img src={NavigationArrowIcon} alt="Arrow-image" />
          </span>
        </div>
        <div className="value">Sarah Brown</div>
      </div>
      <div className="member-detail-container-outer">
        <div className="member-details-part-one">
          <div className="left-part">
            <div className="member-picture">
              <img
                // src={member_data.data.basic_user_info.image}
                src={Avatar}
              />
            </div>
            <div className="name-designation">
              <span className="member-name-header">Sarah Brown</span>
              <span className="member-designation-header">Data Analyst</span>
            </div>
          </div>
          <div className="right-part">
            <button className="deactivate-btn">
              <span className="dustbin">
                <img src={DeleteIcon} alt="Delete-button" />
              </span>
              Deactivate User
            </button>
          </div>
        </div>
        <div className="member-details-part-two">
          <div className="basic-info">
            <div className="heading-basic-info">BASIC INFORMATION</div>
            <div className="keys-values">
              <div className="column-keys-values">
                <div className="keys-values-section">
                  <div className="key">Email ID</div>
                  <div className="value">
                    sarahbrown@example.com
                    {/* {member_data && member_data.data.email} */}
                  </div>
                </div>
                <div className="keys-values-section">
                  <div className="key">Date Joined</div>
                  <div className="value">
                    January 15, 2020
                    {/* {member_data && member_data.data.date_of_birth} */}
                  </div>
                </div>
              </div>
              <div className="column-keys-values">
                <div className="keys-values-section">
                  <div className="key">Date of Birth</div>
                  <div className="value">
                    January 1, 1990
                    {/* {member_data && member_data.data.date_of_birth} */}
                  </div>
                </div>
                <div className="keys-values-section">
                  <div className="key">Designation</div>
                  <div className="value">
                    Data Analyst
                    {/* {member_data && member_data.data.date_of_birth} */}
                  </div>
                </div>
              </div>
              <div className="column-keys-values">
                <div className="keys-values-section">
                  <div className="key">Gender</div>
                  <div className="value">
                    Female
                    {/* {member_data && member_data.data.email} */}
                  </div>
                </div>
                <div className="keys-values-section">
                  <div className="key">Address</div>
                  <div className="value">
                    123 Main St, Anytown, USA
                    {/* {member_data && member_data.data.date_of_birth} */}
                  </div>
                </div>
              </div>
              <div className="column-keys-values">
                <div className="keys-values-section">
                  <div className="key">Phone Number</div>
                  <div className="value">
                    123-456-7890
                    {/* {member_data && member_data.data.email} */}
                  </div>
                </div>
                <div className="keys-values-section">
                  <div className="key">Emergency Contact Number</div>
                  <div className="value">
                    234-567-8901
                    {/* {member_data && member_data.data.date_of_birth} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="member-details-part-three">
          <div className="left-div">
            <div className="left-upper-div">
              <div className="heading-education">EDUCATION</div>
              <div className="education-data">
                <div className="history">
                  <div className="left-div">
                    <div className="icon-bachelor">
                      <img src={BachelorIcon} alt="BachelorDegree-image" />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="course">
                      Bachelor of Science in Computer Science
                    </div>
                    <div className="organization-name">
                      University of California, Berke
                    </div>
                    <div className="passing-year">
                      <span className="key">Graduated:</span>
                      <span className="value">May 2016</span>
                    </div>
                    <div className="score">
                      <span className="key">GPA:</span>{" "}
                      <span className="value">3.8/4.0</span>
                    </div>
                  </div>
                </div>
                <div className="history">
                  <div className="left-div">
                    <div className="icon-master">
                      <img src={MasterIcon} alt="MasterDegree-image" />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="course">
                      Master of Science in Information System
                    </div>
                    <div className="organization-name">Stanford University</div>
                    <div className="passing-year">
                      <span className="key">Graduated:</span>
                      <span className="value">June 2019</span>
                    </div>
                  </div>
                  <div className="score">
                    <span className="key">GPA:</span>{" "}
                    <span className="value">4.0/4.0</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="left-lower-div">
              <div className="heading-certifications">CERTIFICATIONS</div>
              <div className="certication-data">
                <div className="history">
                  <div className="left-div">
                    <div className="icon-certification">
                      <img src={CertificatesIcon} alt="certificate-image" />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="certified-course">
                      AWS Certified Solutions Architect – Associate
                    </div>
                    <div className="issued-year">
                      <span className="key">Issued:</span>
                      <span className="value">August 2022</span>
                    </div>
                    <div className="certified-id">
                      <span className="key">Certification ID:</span>
                      <span className="value">ABC123456789</span>
                    </div>
                  </div>
                </div>
                <div className="history">
                  <div className="left-div">
                    <div className="icon-certification">
                      <img src={CertificatesIcon} alt="certificate-image" />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="certified-course">
                      Certified Scrum Master (CSM)
                    </div>
                    <div className="issued-year">
                      <span className="key">Issued:</span>
                      <span className="value">March 2021</span>
                    </div>
                    <div className="certified-id">
                      <span className="key">Certification ID:</span>
                      <span className="value">XYZ987654321</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="middle-div">
            <div className="middle-upper-div">
              <div className="heading-experience">EXPERIENCE</div>
              <div className="experience-data">
                <div className="history">
                  <div className="left-div">
                    <div className="icon-experience">
                      <div className="ordered-list-dot"></div>
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="designation">Software Engineer</div>
                    <div className="organization-name">Tech Solutions</div>
                    <div className="working-year">June 2019 - Present</div>
                    <div className="location">San Francisco, CA</div>
                  </div>
                </div>
                <div className="history">
                  <div className="left-div">
                    <div className="icon-experience">
                      <div className="ordered-list-dot"></div>
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="designation">Intern Software Developer</div>
                    <div className="organization-name">InnovateX</div>
                    <div className="working-year">September 2018</div>
                    <div className="location">New York, NY</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="middle-lower-div">
              <div className="heading-awards&recognitions">
                AWARDS & RECOGNITIONS
              </div>
              <div className="awards&recognition-data">
                <div className="history">
                  <div className="left-div">
                    <div className="icon-employee-month">
                      <img src={EmployeeMonthIcon} alt="EmployeeMonth-image" />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="title">Employee of the Year</div>
                    <div className="event-name">Tech Solutions, 2022</div>
                    <div className="description">
                      Recognized for exceptional contributions to the product
                      engineering team.
                    </div>
                  </div>
                </div>
                <div className="history">
                  <div className="left-div">
                    <div className="icon-employee-month">
                      <img
                        src={TopInnovatorIcon}
                        alt="TopInnovatorIcon-image"
                      />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="title">Top Innovator Award</div>
                    <div className="event-name">InnovateX Hackathon, 2018</div>
                    <div className="description">
                      Awarded for creating a prototype AI assistant.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-div">
            <div className="right-upper-div">
              <div className="heading-skills">SKILLS</div>
              <div className="skills-data">
                <div className="skill-list">Python</div>
                <div className="skill-list">C++</div>
                <div className="skill-list">Javascript</div>
                <div className="skill-list">HTML</div>
              </div>
            </div>
            <div className="right-middle-div">
              <div className="heading-personalwebsite">PERSONAL WEBSITE</div>
              <div className="website-data">
                <div className="history">
                  <div className="left-div">
                    <div className="icon-globe">
                      <img src={GlobeIcon} alt="Globe-image" />
                    </div>
                  </div>
                  <div className="right-div">
                    <div className="type">Website</div>
                    <div className="website-url">
                      University of California, Berke
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-lower-div">
              <div className="heading-languages">LANGUAGES</div>
              <div className="languages-data">
                <div className="languages-list">
                  <div className="listitem-dot"></div>
                  <div className="name&level">
                    <span className="language-name">English</span>
                    <span className="language-level">(Fluent)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="member-details-part-four">
          <div className="conference-event">
            <div className="heading-conference-event">CONFERENCE EVENT</div>
            <div className="conference-event-data">
              <div className="history">
                <div className="left-div">
                  <div className="icon-conference">
                    <div className="ordered-list-dot"></div>
                  </div>
                </div>
                <div className="right-div">
                  <div className="title">Tech Innovators 2023</div>
                  <div className="topic-name">
                    <span className="key">Topic:</span>
                    <span className="value">
                      Revolutionizing User Experience with AI-driven Interfaces
                    </span>
                  </div>
                  <div className="eventdate">
                    <span className="key">Event Date:</span>
                    <span className="value">September 15, 2023</span>
                  </div>
                  <div className="venue">
                    <span className="key">Venue:</span>
                    <span className="value">
                      Moscone Center, San Francisco, CA
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="projects">
            <div className="heading-projects">PROJECTS</div>
            <div className="projects-data">
              <div className="history">
                <div className="left-div">
                  <div className="icon-projects">
                    <div className="ordered-list-dot"></div>
                  </div>
                </div>
                <div className="right-div">
                  <div className="title">SmartHome Automation</div>
                  <div className="description">
                    Developed a smart home system that integrates IoT devices to
                    provide automated control and monitoring via a mobile app.
                  </div>
                </div>
              </div>
              <div className="history">
                <div className="left-div">
                  <div className="icon-projects">
                    <div className="ordered-list-dot"></div>
                  </div>
                </div>
                <div className="right-div">
                  <div className="title">Eco-friendly Ride Sharing App</div>
                  <div className="description">
                    Created a ride-sharing platform with a focus on reducing
                    carbon footprint by suggesting eco-friendly routes and
                    vehicle-sharing options.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="member-details-part-five">
          <div className="profiles">
            <div className="heading-profiles">PROFILES</div>
            <div className="profiles-data">
              <div className="history">
                <div className="left-div">
                  <div className="user-profile-image">
                    <img src={Avatar} alt="User-image" />
                  </div>
                  <div className="user-name">Sarah Brown</div>
                  <div className="user-designatioon">Analyst</div>
                </div>
                <div className="right-div">
                  <div className="phone-number">
                    <div className="phone-icon">
                      <img src={CallIcon} alt="phone-icon" />
                    </div>
                    <div className="number">(555) 123-4567</div>
                  </div>
                  <div className="email">
                    <div className="email-icon">
                      <img src={MessageIcon} alt="email-icon" />
                    </div>
                    <div className="email-details">sarahbrown@example.com</div>
                    <div className="view-details">
                      <button>
                        View Details
                        <span className="arrow">
                          <img
                            src={NavigationArrowIcon}
                            alt="navigation-arrow-image"
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MemberDetails;
