import React from 'react';
import moment from 'moment';
import './weekview.scss';

const WeekView = ({ filters, data, selectedDate }) => {
  const startOfWeek = moment(selectedDate).startOf('week');
  const endOfWeek = moment(selectedDate).endOf('week');

  const filteredData = (type) => {
    return data[type].filter(item =>
      moment(item.date).isBetween(startOfWeek, endOfWeek, null, '[]')
    );
  };

  const daysInWeek = Array.from({ length: 7 }, (_, i) => moment(startOfWeek).add(i, 'days'));

  // Generate style for each event item based on the start and end time
  const generateEventStyle = (startTime, endTime) => {
    const startHour = moment(startTime, 'HH:mm').hours(); // Get start hour (0-23)
    const startMinute = moment(startTime, 'HH:mm').minutes(); // Get start minute
    const endHour = moment(endTime, 'HH:mm').hours(); // Get end hour (0-23)
    const endMinute = moment(endTime, 'HH:mm').minutes(); // Get end minute

    const top = startHour * 60 + startMinute; // Convert start time to position in pixels
    const height = ((endHour * 60 + endMinute) - (startHour * 60 + startMinute)); // Duration in pixels

    return {
      top: `${top}px`,
      height: `${height}px`,
    };
  };

  return (
    <div className="week-view">
      <div className="week-header">
        <h2>{startOfWeek.format('MMM Do')} to {endOfWeek.format('MMM Do')}</h2>
      </div>
      <div className="week-container">
        {/* Date Headers */}
        <div className="week-date-headers">
          <div className="time-column-header"></div> {/* Placeholder for time column */}
          {daysInWeek.map(day => (
            <div key={day} className="day-header">
              {day.format('ddd D')}
            </div>
          ))}
        </div>

        {/* Scrollable Event Content */}
        <div className="week-events-scrollable">
          <div className="time-column">
            {Array.from({ length: 24 }, (_, i) => (
              <div key={i} className="time-slot">
                {i}:00
              </div>
            ))}
          </div>
          <div className="days-events">
            {daysInWeek.map(day => (
              <div key={day} className="day-column">
                <div className="day-events">
                  {/* Display Meetings */}
                  {filters.meetings &&
                    filteredData('meetings')
                      .filter(meeting => moment(meeting.date).isSame(day, 'day'))
                      .map(meeting => (
                        <div
                          className="event-item meeting"
                          key={meeting.calendar_id}
                          style={generateEventStyle(meeting.time.split(' - ')[0], meeting.time.split(' - ')[1])}
                        >
                          <p>{meeting.title}</p>
                          <span>{meeting.time}</span>
                        </div>
                      ))
                  }

                  {/* Display Events */}
                  {filters.events &&
                    filteredData('events')
                      .filter(event => moment(event.date).isSame(day, 'day'))
                      .map(event => (
                        <div
                          className="event-item event"
                          key={event.calendar_id}
                          style={generateEventStyle(event.time.split(' - ')[0], event.time.split(' - ')[1])}
                        >
                          <p>{event.title}</p>
                          <span>{event.time}</span>
                        </div>
                      ))
                  }

                  {/* Display Announcements */}
                  {filters.announcements &&
                    filteredData('announcements')
                      .filter(announcement => moment(announcement.date).isSame(day, 'day'))
                      .map(announcement => (
                        <div
                          className="event-item announcement"
                          key={announcement.calendar_id}
                          style={generateEventStyle(announcement.announcement_fk.scheduled_time.split(' - ')[0], announcement.announcement_fk.scheduled_time.split(' - ')[1])}
                        >
                          <p>{announcement.announcement_fk.title}</p>
                          <span>{announcement.announcement_fk.scheduled_time}</span>
                        </div>
                      ))
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekView;
