import React, { useEffect, useRef, useState } from "react";
import {
  useGetCalendarEventsQuery,
} from "../../features/users/usersApiSlice.js";
import {
  useCreateChannelsMutation,
  useGetSubscribedChannelsQuery,
} from "../../features/channels/channelsApiSlice.js";

import Calendar from "../../components/calendar/Calendar.js";
// import { AddSubChannelPopup } from "../../components/popups/AddSubChannelPopup.js";
// import { setMessages } from "../store/actions/message";

const CalendarView = () => {


  return (
    <div className="chat-container">
      <Calendar />
    </div>
  );
};

export default CalendarView;
