import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import {
    useCreateAnnouncementMutation,
    useUpdateAnnouncementMutation
} from "../../features/users/usersApiSlice";
import './Announcement.scss';

// Consolidate image imports
const images = {
    pencil: require('../../assets/img/ci_edit-pencil-line-01.png'),
    clarity: require('../../assets/img/clarity_details-line.png'),
    frame: require('../../assets/img/Frame 627337.png'),
    globe: require('../../assets/img/Vector (5).png'),
    linkIcon: require('../../assets/img/lucide_link.png'),
    frame1: require('../../assets/img/Frame 627336 (1).png'),
    calendar: require('../../assets/img/mdi_calendar.png')
};

const validationSchema = Yup.object({
    title: Yup.string()
        .min(3, 'Title must be at least 3 characters')
        .required('Title is required'),
    description: Yup.string()
        .min(10, 'Description must be at least 10 characters')
        .required('Description is required'),
    date: Yup.date()
        .required('Date is required')
        .test('is-today-or-future', 'Date cannot be in the past', (value) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // set to midnight
            return value && value >= today;
        }),
    startTime: Yup.string()
        .required('Start time is required')
        .test('is-valid-time', 'Start time cannot be in the past', function (value) {
            const selectedDate = this.parent.date; // Get the selected date
            const today = new Date(); // Current date and time

            if (selectedDate) {
                const selectedDateTime = new Date(selectedDate);
                const [hours, minutes] = value.split(':');
                selectedDateTime.setHours(hours, minutes, 0, 0);

                // Check if the selected date/time is in the future
                if (selectedDateTime < today) {
                    return false; // Invalid: the selected time is in the past
                }
            }

            return true; // Valid
        }),
    link: Yup.string().url('Enter a valid URL'),
});

const AddAnnouncement = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const currentDate = new Date();
    const newStartTime = new Date(currentDate.getTime() + 10 * 60000);
    const formattedStartTime = newStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const initialValues = useMemo(() => ({
        title: '',
        description: '',
        date: new Date(),
        startTime: formattedStartTime,
        location: state.name,
        link: '',
    }), [state.name]);

    const [createAnnouncementMutation] = useCreateAnnouncementMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const { date, startTime, title, description, link } = values;
            const isoString = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T${startTime}:00Z`;

            const data = {
                title,
                content: description,
                scheduled_time: isoString,
                location_fk: state.itemId,
                link
            };

            try {
                const response = await createAnnouncementMutation(data);
                if (response.data) {
                    NotificationManager.success('Announcement Created', 'Announcement created successfully');
                    console.log("Announcement created successfully:", response.data);
                    navigate(-1);
                } else {
                    NotificationManager.error('Annountment is not created', 'Error creating announcement');
                    console.error("Error creating announcement:", response.error);
                }
            } catch (error) {
                NotificationManager.error('Annountment is not created', 'Error creating announcement');
                console.error("Failed to create announcement:", error);
            }
        },
    });

    const renderError = (field) => (
        formik.touched[field] && formik.errors[field] && (
            <div className="error">{formik.errors[field]}</div>
        )
    );

    return (
        <div className="add-announcement-form">
            <h2>Create Announcement</h2>
            <p>Share Updates Seamlessly with your Team</p>
            <hr />
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <div className="add-images">
                        <img src={images.frame1} alt="Icon" className="input-icon" />
                        <label>What’s your announcement about?</label>
                    </div>
                    <div className='title'>Title</div>
                    <div className="input-wrapper">
                        <img src={images.pencil} alt="Icon" className="input-icon" />
                        <input
                            type="text"
                            name="title"
                            placeholder="Enter announcement title"
                            maxLength="50"
                            {...formik.getFieldProps('title')}
                        />
                    </div>
                    {renderError('title')}
                    <div className='description'>Description</div>
                    <div className="input-wrapper input-wrapper-description">
                        <img src={images.clarity} alt="Icon" className="input-icon" />
                        <textarea
                            className='description-input'
                            name="description"
                            placeholder="Enter announcement details here..."
                            maxLength="500"
                            {...formik.getFieldProps('description')}
                        />
                    </div>
                    {renderError('description')}
                </div>

                <div className="form-group">
                    <div className="add-images">
                        <img src={images.frame} alt="Icon" className="input-icon" />
                        <label>When and where will it take place?</label>
                    </div>

                    <div className="date-time-wrapper">
                        <div className="custom-date-input">
                            <label className='date'>Date</label>
                            <DatePicker
                                selected={formik.values.date}
                                onChange={(date) => formik.setFieldValue('date', date)}
                                onBlur={() => formik.setFieldTouched('date', true)}
                                dateFormat="yyyy/MM/dd"
                                className="date-picker"
                            />
                            <img src={images.calendar} alt="calendar-icon" className="calendar-icon" />
                        </div>

                        <div className="time-input-wrapper">
                            <label className="start-time-label">At</label>
                            <input
                                type="time"
                                name="startTime"
                                value={formik.values.startTime}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched('startTime', true)}
                                className="time-picker"
                            />
                        </div>
                    </div>
                    {renderError('date')}
                    {renderError('startTime')}
                </div>
                <div className="form-group">
                <div className='location'>Location</div>
                <div className="input-wrapper">
                    <img src={images.globe} alt="Icon" className="input-icon" />
                    <input
                        type="text"
                        name="location"
                        disabled
                        value={formik.values.location}
                    />
                </div>
                {renderError('location')}
                <div className='link'>Link <span style={{ color: "grey" }}>(Optional)</span></div>
                <div className="link-input-wrapper">
                    <img src={images.linkIcon} alt="Icon" className="input-icon" />
                    <input
                        type="url"
                        name="link"
                        className='input'
                        placeholder="Link to the meeting"
                        {...formik.getFieldProps('link')}
                    />
                </div>
                {renderError('link')}
                </div>

                <div className="form-actions">
                    <button type="button" className="cancel-btn" onClick={() => navigate(-1)}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="create-btn"
                        disabled={
                            !formik.values.title ||
                            !formik.values.description ||
                            Object.keys(formik.errors).length > 0
                        }>
                        Create
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddAnnouncement;
