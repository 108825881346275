import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import axios from 'axios';
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../features/auth/authSlice";
import Select, { components, MenuListProps } from "react-select";
import { React, useState, useContext, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { AddLocationPopup } from "../popups/AddLocationPopup";
import { DeleteLocationPopup } from "../popups/DeleteLocationPopup";
import { AddBranchPopup } from "../popups/AddBranchPopup";
import { BranchDetailPopup } from "../popups/BranchDetailPopup";
import parsePhoneNumber from 'libphonenumber-js'
import { TailSpin } from 'react-loader-spinner'

import { BASE_URL } from "../../app/api/apiSlice";
import {
  useUpdateOrganisationsMutation,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetUsersQuery,
  useGetOrganisationLocationsQuery,
  useCreateBranchMutation,
  useDeleteBranchMutation,
  useCreateOrganisationMembersMutation,
  useGetPluginsQuery,
  useUpdatePluginMutation
} from "../../features/users/usersApiSlice";
import * as XLSX from "xlsx";
import { store } from "../../app/store";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  AiOutlineInfoCircle,
  AiFillPlusCircle,
  AiOutlineMinusCircle,
  AiOutlineDownload,
} from "react-icons/ai";
import { NotificationManager } from 'react-notifications';

import { yearList } from "../utils/utils";
import { setCredentials } from "../../features/auth/authSlice";
import { formatDateToYYYYMMDD, excelDateToJSDate } from "../utils/utils";
import LogoPlaceholder from "../../../src/assets/img/logo-placeholder.png";
import "./new_organisation.scss";
// const { getCountryCallingCode, getCountries } = require('libphonenumber-js');
import { continents, countries, languages } from 'countries-list'
// Utils



// import './styles.scss'

const NewOrgansation = () => {
  const user = useSelector(selectCurrentUser);
  // const countryList = getCountries();

  const token = useSelector(selectCurrentToken);
  const [activeKey, setActiveKey] = useState("tab1");
  const [organisationName, setOrganisationName] = useState(user && user.organisation.name_nn);
  const [organisationWebsite, setOrganisationWebsite] = useState(user.organisation.website);
  const [organisationDescription, setOrganisationDescription] = useState(user.organisation.description);
  const [organisationLocation, setOrganisationLocation] = useState(user.organisation.headquarters);
  const [organisationCategory, setOrganisationCategory] = useState(user.organisation.industry);
  const [organisationPhone, setOrganisationPhone] = useState("");
  const [organisationFoundedYear, setOrganisationFoundedYear] = useState(user.organisation.founded_year);
  const [organisationEmail, setOrganisationEmail] = useState(user.organisation.contact_email);
  const [membersList, setMembersList] = useState([]);
  const [showAddLocationPopup, setShowAddLocationPopup] = useState(false);
  const [showAddBranchPopup, setShowAddBranchPopup] = useState(false);
  const [showBranchDetailsPopup, setShowBranchDetailsPopup] = useState(false);
  const [showDeleteLocationPopup, setShowDeleteLocationPopup] = useState(false);
  const [newLocationDirectoryIndex, setNewLocationDirectoryIndex] = useState(0);
  const [newLocationParent, setNewLocationParent] = useState("");
  const [tab1loading, setTab1loading] = useState(false)
  const [locationUpdateLoading, setLocationUpdateLoading] = useState(false)
  const [deleteLocationDirectoryIndex, setDeleteLocationDirectoryIndex] =
    useState(0);
  const [deleteLocationName, setDeleteLocationName] = useState("");
  const [deleteLocationId, setDeleteLocationId] = useState("");
  const [branchDetailsData, setBranchDetailsData] = useState({});
  const [newBranchLocationName, setNewBranchLocationName] = useState("");
  const [newBranchLocationId, setNewBranchLocationId] = useState("");
  const [year, setYear] = useState({
    value: new Date().getFullYear().toString(),
    label: new Date().getFullYear(),
  });
  // console.log(user, user.organisation, user.organisation.name !== null && user.organaisation.name !== "");
  const fileInputRef = useRef(null);
  const [country, setCountry] = useState({
    value: 91,
    label: "India (+91)"
  })
  const [countriesList, setCountriesList] = useState([])
  const [organisationError, setorganisationError] = useState({
    image: null,
    name: null,
    email: null,
    phoneNumber: null,
    location: null,
    website: null,
    industryCategory: null,
    description: null
  })

  console.log(user, "FFF");


  // countryList.forEach((country) => {
  //   console.log("AFSF: ", country);
  //   const phoneCode = getCountryCallingCode(country.alpha2);
  //   console.log(`${country.name}: +${phoneCode}`);
  // });

  const { data: userData, isLoading: isLoading, isSuccess: isSuccess, isError: isError, error: error, refetch: refetch_users } =
    useGetUsersQuery();
  const {
    data: locationData,
    isLoading: isLoadingLocationData,
    isSuccess: isSuccessLocationData,
    isError: isErrorLocationData,
    error: errorLocationData,
    refetch: refetch_locations,
  } = useGetOrganisationLocationsQuery({ parent_id: null });
  const dispatch = useDispatch();

  const {
    data: pluginData,
    isLoading: isLoadingPluginData,
    isSuccess: isSuccessPluginData,
    isError: isErrorPluginData,
    error: errorPluginData,
    refetch: refetch_plugin,
  } = useGetPluginsQuery();

  const [
    updatePlugin,
    { isLoading: updatePluginIsLoading }
  ] = useUpdatePluginMutation();

  const checkDataValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newError = {
      image: null,
      name: null,
      email: null,
      phoneNumber: null,
      location: null,
      website: null,
      industryCategory: null,
      description: null
    }
    if (logoFile === undefined) {
      newError.image = "Upload proper image"
    }
    if (organisationName.length < 2) {
      newError.name = "Enter name greater than 2 character length"
    }
    if (organisationLocation === "") {
      newError.location = "Enter proper location"
    }
    if (!emailRegex.test(organisationEmail)) {
      newError.email = "Invalid Email"
    }
    if (organisationPhone === "") {
      newError.phoneNumber = "Invalid Phone number"
    }
    if (organisationWebsite === "") {
      // Test the email against the regular expression
      newError.website = "Invalid Website"
    }
    if (organisationCategory.length < 2) {
      newError.industryCategory = "Enter category greater than 2 character length"
    }
    if (organisationDescription.length < 10) {
      newError.description = "Enter description greater than 10 character length"
    }
    setorganisationError(newError)

    const hasNullValue = Object.values(newError).some(value => value !== null);

    if (hasNullValue) {
      console.log("LLLL: ", newError);
      return false
    } else {
      return true
    }

  }

  // const {
  //   data: getTemplate,
  //   isLoading: isLoadinGetUsersTemplate,
  //   isSuccess: isSuccessGetUsersTemplatea,
  //   isError: isErrorGetUsersTemplate,
  //   error: errorGetUsersTemplate,
  //   refetch: refetch_users_template,
  // } = useGetUsersTemplateQuery();

  const [selectedExcelFile, setSelectedExcelFile] = useState(null);

  const [updateOrganisation, { isLoading: updateOrganisationIsLoading }] =
    useUpdateOrganisationsMutation();

  const [createOrganisationMembers, { isLoading: createOrganisationMembersIsLoading }] =
    useCreateOrganisationMembersMutation();

  const [createLocation, { isLoading: createLocationIsLoading }] =
    useCreateLocationMutation();

  const [deleteLocation, { isLoading: deleteLocationIsLoading }] =
    useDeleteLocationMutation();

  const [deleteBranch, { isLoading: deleteBranchIsLoading }] =
    useDeleteBranchMutation();

  const [createBranch, { isLoading: createBranchIsLoading }] =
    useCreateBranchMutation();

  const [logoFile, setLogoFile] = useState();

  const handleOrganisationNameInput = (e) => {
    setOrganisationName(e.target.value);
  };
  const handleOrganisationWebsiteInput = (e) => {
    setOrganisationWebsite(e.target.value);
  };
  const handleOrganisationEmailInput = (e) => {
    setOrganisationEmail(e.target.value);
  };
  const handleOrganisationDescriptionInput = (e) => {
    setOrganisationDescription(e.target.value);
  };
  const handleOrganisationLocationInput = (e) => {
    setOrganisationLocation(e.target.value);
  };
  const handleOrganisationCategoryInput = (e) => {
    setOrganisationCategory(e.target.value);
  };
  const handleOrganisationPhoneInput = (e) => {
    console.log(e.target.value.toString());
    if (e.target.value.toString().length > 10) {
      // Optionally display an error message
      console.log("asga");
      return; // Exit the function without setting the state
    }
    setOrganisationPhone(e.target.value);
  };
  const handleOrganisationFoundedYearInput = (e) => {
    setOrganisationFoundedYear(e.target.value);
  };
  const handleOrganisationLogo = (event) => {
    setLogoFile(event.target.files[0]);
  };

  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  const onAddLocationPopupClose = () => {
    setNewLocationParent("");
    setNewLocationDirectoryIndex(0);
    setShowAddLocationPopup(false);
  };
  const onAddBranchPopupClose = () => {
    setNewBranchLocationName("");
    setNewBranchLocationId("");
    setShowAddBranchPopup(false);
  };
  const onBranchDetailsPopupClose = () => {
    setBranchDetailsData("");
    setShowBranchDetailsPopup(false);
  };
  const onDeleteLocationPopupClose = () => {
    setDeleteLocationName("");
    setDeleteLocationId("");
    setDeleteLocationDirectoryIndex(0);
    setShowDeleteLocationPopup(false);
  };
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const getLevelName = () => {
    const count = newLocationDirectoryIndex + 1;
    switch (count) {
      case 0:
        return "Global";
        break;
      case 1:
        return "Continent";
        break;
      case 2:
        return "Country";
        break;
      case 3:
        return "State";
        break;
      case 4:
        return "City";
        break;
      case 5:
        return "Local";
        break;
      default:
        break;
    }
  };
  const [yearOptions, setYearOptions] = useState(yearList);

  const setYearHandler = (e) => {
    console.log(e)
    setYear(e);
    setOrganisationFoundedYear(e.value);
  };
  const setCountryHandler = (e) => {
    setCountry(e)
  }
  function hexToBytes(hex) {
    const bytes = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
      bytes[i / 2] = parseInt(hex.substr(i, 2), 16);
    }
    return bytes;
  }
  const downloadTemplate = async () => {
    try {
      axios({
        url: `${BASE_URL}/organisation/get_user_excel/${user.organisation.id}/`, //your url
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'UploadTemplate.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    } catch (error) {
      console.error('Error downloading template:', error);
    }
  };

  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedExcelFile(file);
  };
  useEffect(() => {
    const temp_countries = Object.entries(countries).map(([code, country]) => ({
      label: `${country.name_nn} (+${country.phone[0].toString()})`,
      value: country.phone[0].toString()
    }));
    setCountriesList(temp_countries)
    // if (user && user.organisation && user.organisation.name !== "" && activeKey !== 'tab4'){
    //   setActiveKey('tab4')
    // }
    refetch_locations();
    console.log("number is", user.organisation.phone_number);
    const lastTenDigits = user.organisation.phone_number.substring(user.organisation.phone_number.length - 10);
    setOrganisationPhone(lastTenDigits)
    let modifiedUrl = user.organisation.website.replace("https://", "");
    modifiedUrl = modifiedUrl.replace("http://", "");
    setOrganisationWebsite(modifiedUrl)
  }, []);

  const excelDateToDateString = (excelDate) => {
    if (!excelDate) {
      return ""; // Handle null or undefined values
    }

    // Excel uses January 1, 1900, as day 1
    const excelDateOffset = 25569; // Number of days between January 1, 1900, and January 1, 1970
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const dateMilliseconds = (excelDate - excelDateOffset) * millisecondsPerDay;

    // Create a Date object and format it as 'yyyy-MM-dd'
    const formattedDate = new Date(dateMilliseconds).toISOString().slice(0, 10);
    return formattedDate;
  };

  const handleReadExcel = () => {
    console.log(selectedExcelFile, "asgags");
    if (selectedExcelFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        processExcelData(data);
      };
      reader.readAsBinaryString(selectedExcelFile);
    } else {
      alert("Please select an Excel file.");
    }
  };
  const processExcelData = (data) => {
    const workbook = XLSX.read(data, { type: 'binary', encoding: 'UTF-8' });
    console.log(workbook);
    const sheetNames = workbook.SheetNames;
    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], {
      defval: null,
    });
    console.log(sheetData);
    sheetData.forEach(entry => {
      if (entry.hasOwnProperty("Phone")) {
        entry.Phone = "+" + entry.Phone.toString();
      }
    });
    const formattedData = sheetData.map((item) => {
      return {
        "First Name": item["First Name"],
        "Last Name": item["Last Name"],
        "Email": item["Email"],
        "Date Of Birth": formatDateToYYYYMMDD(excelDateToJSDate(item["Date Of Birth"])),
        "Branch": item["Branch"],
        "Phone": item["Phone"].toString(),
        "Gender": item["Gender"],
        "Education": item["Education"],
        "Role": item["Role"],
        "Designation": item['Designation'],
        "Experience": item["Experience"],
        "Department": item['Department'],
        "Sub Department": item['Sub Department'],
        "Address": item["Address"]
      }
    })
    setMembersList(formattedData);
  };

  const SubmitOrganisationHandler = async () => {
    if (!checkDataValidation()) {
      return false
    }
    console.log("AMAR1")
    setTab1loading(true)
    const formData = new FormData();
    formData.append("name", organisationName);
    formData.append("website", `https://${organisationWebsite}`);
    formData.append("description", organisationDescription);
    formData.append("founded_year", organisationFoundedYear);
    formData.append("headquarters", organisationLocation);
    formData.append("industry", organisationCategory);
    formData.append("contact_email", organisationEmail);
    formData.append("phone_number", `+${country.value}${organisationPhone}`);
    formData.append("logo", logoFile);
    console.log(formData, "asfasf",);
    // formData.append("members", JSON.stringify(membersList))

    const response = await updateOrganisation(formData);
    console.log("update org " + JSON.stringify(response));
    setTab1loading(false)
    if (response.data.data && response.errors === undefined) {
      await refetch_locations()
      setActiveKey("tab4");
      // setActiveKey("tab2");
    }

    const result = await refetch_users();
    if (result && result.data && result.data.data && result.data.response_data) {
      console.log(result, "sdgsdgsdg")
      dispatch(setCredentials({ user: null, user_data: result.data.response_data.user_data, access: token }))
    };
  }
  // const SubmitOrganisationMembersHandler = async () => {
  //   // const formData = new FormData();
  //   // formData.append("members", JSON.stringify(membersList))

  //   const data = {

  //     "members": membersList
  //   }
  //   console.log("LLLKKK: ", data);
  //   const response = await createOrganisationMembers(data);
  //   console.log(response);
  //   if (response.data && response.errors === undefined) {
  //     setActiveKey("tab7");
  //   }

  //   const result = await refetch();
  //   dispatch(setCredentials({ user: null, user_data: result.data.data.response_data.user_data, access: token }))
  //   window.location.reload();
  // };

  const SubmitAddLocationHandler = async (level, parent) => {
    setNewLocationParent(parent);
    setNewLocationDirectoryIndex(level);
    setShowAddLocationPopup(true);
  };

  const SubmitBranchDetailHandler = async (data) => {
    setBranchDetailsData(data);
    setShowBranchDetailsPopup(true);
  };
  const SubmitAddBranchHandler = async (id, name) => {
    setNewBranchLocationId(id);
    setNewBranchLocationName(name);
    setShowAddBranchPopup(true);
  };

  const SubmitDeleteLocationHandler = async (id, level, name) => {
    setDeleteLocationName(name);
    setDeleteLocationId(id);
    setDeleteLocationDirectoryIndex(level);
    setShowDeleteLocationPopup(true);
  };

  const SubmitAddLocationPopupHandler = async (name) => {

    setLocationUpdateLoading(true)
    const data = {
      name_nn: name,
      level: newLocationDirectoryIndex + 1,
      parent_fk: newLocationParent,
    };
    // formData.append("members", JSON.stringify(membersList))

    const response = await createLocation(data);
    console.log(response);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
    setLocationUpdateLoading(false)
    // dispatch(setCredentials({user: null, user_data: result.data.response_data.user_data, access: token }))
  };
  const updatePluginHandler = async (id) => {
    const data = {
      id: id,
      initial: true
    }
    const response = await updatePlugin(data);
    console.log(response);
    if (response.data) {
      const result = await refetch_users();
      console.log(result, "result");
      if (result.data && result.data?.data) {
        // dispatch(setCredentials({ user: null, user_data: result.data.response_data.user_data, access: token }))
        console.log(result.data, "sdghdfhdfhhhhhh");

        dispatch(setCredentials({
          user: null,
          user_data: result.data.data.user_data,
          access: token
        }));
        window.location.reload();
        NotificationManager.success('Onboarding Started', 'Members onbaording started');
      }

    }
  }
  const SubmitAddBranchPopupHandler = async ({
    branchName,
    branchAddress,
    branchPhone,
  }) => {
    const data = {
      location_fk: newBranchLocationId,
      branch_name_nn: branchName,
      branch_address: branchAddress,
      branch_phone: branchPhone,
    };
    // formData.append("members", JSON.stringify(membersList))

    const response = await createBranch(data);
    console.log(response);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
    // dispatch(setCredentials({user: null, user_data: result.data.response_data.user_data, access: token }))
  };

  const SubmitDeleteLocationPopupHandler = async () => {
    const data = {
      location_id: deleteLocationId,
    };
    // formData.append("members", JSON.stringify(membersList))

    const response = await deleteLocation(data);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
    // dispatch(setCredentials({user: null, user_data: result.data.response_data.user_data, access: token }))
  };

  const SubmitBranchDetailsPopupHandler = async () => {
    const data = {
      branch_id: branchDetailsData.branch_id,
    };
    // formData.append("members", JSON.stringify(membersList))

    const response = await deleteBranch(data);
    if (response.data && response.errors === undefined) {
      refetch_locations();
    }
    // dispatch(setCredentials({user: null, user_data: result.data.response_data.user_data, access: token }))
  };

  return (
    <div className="add-organisation-outer">
      <Tabs
        id="controlled-tab-example"
        activeKey={activeKey}
        onSelect={handleTabSelect}
      >
        <Tab eventKey="tab1" title="Tab 1">
          <div
            className={`tab-content ${activeKey === "tab1" ? "active-tab tab" : "tab"
              }`}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="tab-heading">Create Organisation</div>
            <div className="tab-sub-heading">Upload a logo and update your organisation information.</div>
            <div className="organisation-name-outer">
              {tab1loading && <div className="loading-component">
                <TailSpin
                  visible={true}
                  height="50"
                  width="50"
                  color="#03a9f4"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                />
              </div>
              }
              <div className="organisation-name-inner" style={tab1loading ? { filter: 'blur(2px)' } : {}}>
                <div className="organisation-log-outer">
                  <div className="line-1">Organisation Name</div>
                  <div className="section-2">
                    <div
                      className="organisation-logo"
                      style={logoFile !== null ? { border: '2px solid #bfdbf8' } : {}}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={logoFile ? URL.createObjectURL(logoFile) : LogoPlaceholder}
                      />
                    </div>
                    <div className="upload-section">
                      <div className="upload-btn-outer">
                        <button
                          className="upload-logo-btn-outer"
                          onClick={openFileInput}
                        >
                          Upload
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          id="organisation-name"
                          accept=".png"
                          onChange={handleOrganisationLogo}
                        />
                      </div>
                      <div className="file-type">.png, .jpeg, .git files upto 5 MB.</div>
                      <div className="file-limit">Recommeded size is 250x250px</div>
                    </div>
                  </div>
                  {organisationError.image !== null && <div className="error-message">{organisationError.image}</div>}
                </div>

                <div className="label">
                  Organisation Name
                </div>
                <div className="input-outer">
                  <input
                    type="text"
                    id="organisation-name"
                    className="input-field"
                    placeholder="Enter your organisation name"
                    onChange={handleOrganisationNameInput}
                    value={organisationName}
                    required
                  />
                  {organisationError.name !== null && <div className="error-message">{organisationError.name}</div>}
                </div>

                <div className="label">Estaiblished In</div>
                <div className="input-outer">
                  <Select
                    isSearchable={false}
                    className="dropdown-item"
                    value={year}
                    onChange={setYearHandler}
                    options={yearOptions}
                  />
                </div>

                <div className="label">
                  Headquater
                </div>
                <div className="input-outer">
                  <input
                    type="text"
                    id="organisation-location"
                    className="input-field"
                    placeholder="Enter your organisation location"
                    onChange={handleOrganisationLocationInput}
                    value={organisationLocation}
                    maxLength={40}
                    required
                  />
                  {organisationError.location !== null && <div className="error-message">{organisationError.location}</div>}
                </div>

                <div className="label">Email</div>
                <div className="input-outer">
                  <input
                    type="email"
                    id="organisation-email"
                    className="input-field"
                    placeholder="Enter your email address"
                    onChange={handleOrganisationEmailInput}
                    value={organisationEmail}
                    maxLength={40}
                    required
                  />
                  {organisationError.email !== null && <div className="error-message">{organisationError.email}</div>}
                </div>

                <div className="label">Phone Number</div>
                <div className="input-outer" style={{ display: 'flex', alignItems: "center" }}>
                  <Select
                    isSearchable={false}
                    className="dropdown-item"
                    styles={{ width: "30%" }}
                    value={country}
                    onChange={setCountryHandler}
                    options={countriesList}
                  />
                  <input
                    type="number"
                    max='99999999999'
                    id="organisation-phone"
                    className="input-field"
                    style={{ width: "40%" }}
                    onChange={handleOrganisationPhoneInput}
                    value={organisationPhone}
                    required
                  />
                  {organisationError.phoneNumber !== null && <div className="error-message">{organisationError.phoneNumber}</div>}
                </div>

                <div className="label">
                  Website
                </div>
                <div className="input-outer" style={{ display: 'flex' }}>
                  <input
                    className="input-field"
                    disabled
                    style={{
                      flexGrow: 0,
                      width: '126px',
                      marginRight: '10px'
                    }}
                    value="https://"
                  />
                  <input
                    type="text"
                    id="organisation-website"
                    className="input-field"
                    placeholder="Enter your organisation email address"
                    style={{ flexGrow: 1 }}
                    onChange={handleOrganisationWebsiteInput}
                    value={organisationWebsite}
                    maxLength={40}
                    required
                  />
                  {organisationError.website !== null && <div className="error-message">{organisationError.website}</div>}
                </div>

                <div className="label">Industry Category</div>
                <div className="input-outer">
                  <input
                    type="text"
                    id="organisation-category"
                    className="input-field"
                    placeholder="Enter your organisation category"
                    onChange={handleOrganisationCategoryInput}
                    value={organisationCategory}
                    maxLength={30}
                    required
                  />
                  {organisationError.industryCategory !== null && <div className="error-message">{organisationError.industryCategory}</div>}
                </div>

                <div className="label">Description</div>
                <div className="input-outer">
                  <input
                    type="text"
                    id="organisation-description"
                    className="input-field"
                    placeholder="Enter your description"
                    onChange={handleOrganisationDescriptionInput}
                    value={organisationDescription}
                    required
                  />
                  {organisationError.description !== null && <div className="error-message">{organisationError.description}</div>}
                </div>
              </div>
            </div>
            <div className="nav-buttons-outer">
              <span></span>
              <button
                className={
                  organisationName !== ""
                    ? "nav-btn next-btn"
                    : "nav-btn next-btn nav-btn-inactive"
                }
                disabled={tab1loading}
                onClick={() => {
                  SubmitOrganisationHandler();
                }}
              >
                {tab1loading && <div
                  style={{ marginRight: "10px" }}
                ><TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="#ffffff"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                  />
                </div>
                }
                <div>
                  {tab1loading ? "Saving" : "Next"}
                </div>
              </button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="tab2" title="Tab 2">
          <div
            className={`tab-content ${activeKey === "tab2" ? "active-tab tab" : "tab"
              }`}
          >
            <div className="tab-heading">Organisation Logo</div>
            <div className="organisation-name-outer">
              <div style={{ flexGrow: 1 }}>
                <div className="organisation-name-label-sub">
                  This logo we reflect throughout your organition for all
                  members linked.
                </div>
                <div className="organisation-name-label">
                  Please upload you logo in png format and 50px X 50px
                </div>
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="organisation-name"
                    accept=".png"
                    onChange={handleOrganisationLogo}
                  />
                </div>
              </div>
            </div>
            <div className="nav-buttons-outer">
              <button
                className="nav-btn back-btn"
                onClick={() => {
                  setActiveKey("tab1");
                }}
              >
                Back
              </button>
              <button
                className={
                  logoFile
                    ? "nav-btn next-btn"
                    : "nav-btn next-btn nav-btn-inactive"
                }
                onClick={() => {
                  setActiveKey("tab3");
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="tab3" title="Tab 3">
          <div
            className={`tab-content ${activeKey === "tab3" ? "active-tab tab tab3" : "tab tab3"
              }`}
          >
            <div className="tab-heading">Organisation Details</div>
            <div className="organisation-name-outer">
              <div style={{ flexGrow: 1 }}>
                <div className="organisation-name-label-sub">
                  This name we reflect throughout your organition for all
                  members linked.
                </div>
                <div className="organisation-name-label">Description</div>
                <div>
                  <input
                    type="text"
                    id="organisation-description"
                    className="input-field"
                    onChange={handleOrganisationDescriptionInput}
                    value={organisationDescription}
                    required
                  />
                </div>
                <div className="organisation-name-label">
                  Please enter your organisation website if any
                </div>
                <div>
                  <input
                    type="text"
                    id="organisation-website"
                    className="input-field"
                    onChange={handleOrganisationWebsiteInput}
                    value={organisationWebsite}
                    required
                  />
                </div>

                <div className="organisation-name-label">
                  Main Branch Location
                </div>
                <div>
                  <input
                    type="text"
                    id="organisation-location"
                    className="input-field"
                    onChange={handleOrganisationLocationInput}
                    value={organisationLocation}
                    required
                  />
                </div>
                <div className="organisation-name-label">Industry Category</div>
                <div>
                  <input
                    type="text"
                    id="organisation-category"
                    className="input-field"
                    onChange={handleOrganisationCategoryInput}
                    value={organisationCategory}
                    required
                  />
                </div>
                <div className="organisation-name-label">Contact Email</div>
                <div>
                  <input
                    type="email"
                    id="organisation-email"
                    className="input-field"
                    onChange={handleOrganisationEmailInput}
                    value={organisationEmail}
                    required
                  />
                </div>
                <div className="organisation-name-label">Contact Number</div>
                <div>
                  <input
                    type="text"
                    id="organisation-phone"
                    className="input-field"
                    onChange={handleOrganisationPhoneInput}
                    value={organisationPhone}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="nav-buttons-outer">
              <button
                className="nav-btn back-btn"
                onClick={() => {
                  setActiveKey("tab2");
                }}
              >
                Back
              </button>
              <button
                className={
                  organisationDescription !== "" &&
                    organisationWebsite !== "" &&
                    organisationFoundedYear !== "" &&
                    organisationLocation !== "" &&
                    organisationEmail !== "" &&
                    organisationPhone !== ""
                    ? "nav-btn next-btn"
                    : "nav-btn next-btn nav-btn-inactive"
                }
                onClick={() => {
                  SubmitOrganisationHandler();
                }}
              >
                Save And Proceed
              </button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="tab4" title="Tab 4">
          <div
            className={`tab-content ${activeKey === "tab4" ? "active-tab tab" : "tab"
              }`}
          >
            <div className="tab-heading">Organisation Location</div>
            {locationUpdateLoading && <div className="loading-component">
              <TailSpin
                visible={true}
                height="50"
                width="50"
                color="#03a9f4"
                ariaLabel="tail-spin-loading"
                radius="2"
              />
            </div>
            }
            <div className="organisation-location-outer" style={locationUpdateLoading ? { filter: 'blur(2px)' } : {}}>

              {/* {locationData.data} */}
              <div className="organisation-location-headers-outer row">
                <div className="col-2 organisation-location-header">Global</div>
                <div className="col-2 organisation-location-header">
                  Continent
                </div>
                <div className="col-2 organisation-location-header">
                  Country
                </div>
                <div className="col-2 organisation-location-header">State</div>
                <div className="col-2 organisation-location-header">City</div>
                <div className="col-2 organisation-location-header">Local</div>
              </div>
              <div className="organisation-location-inner">
                {locationData &&
                  locationData.data &&
                  locationData.data.map((globalItem) => {
                    return (
                      <div className="row location-data-outer">
                        <div className="col-2 location-data-inner">
                          <div className="location-data-container">
                            <div className="location-name">
                              {globalItem.name_nn}
                            </div>
                            <div className="location-func-btns">
                              {/* <button className="detail-btn">
                                <AiOutlineInfoCircle />
                              </button> */}

                              {/* <button
                                className="remove-location"
                                onClick={() => {
                                  SubmitDeleteLocationHandler(globalItem.id,0, globalItem.name);
                                }}
                              >
                                <AiOutlineMinusCircle />
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-2 location-data-inner">
                          <button
                            className="add-location"
                            onClick={() => {
                              SubmitAddLocationHandler(0, globalItem.location_id);
                            }}
                          >
                            Add Continent{" "}
                            <span>
                              <AiFillPlusCircle />
                            </span>
                          </button>
                        </div>
                        {globalItem.sublocations.map(
                          (continentItem, continenetIndex) => {
                            return (
                              <div
                                className={
                                  continenetIndex <
                                    globalItem.sublocations.length - 1
                                    ? "row side-marker-continent"
                                    : "row"
                                }
                              >
                                <div className="col-2 curve-marker curve-marker-continent">
                                  <div className="curve curve-continent"></div>
                                </div>
                                <div className="col-2 location-data-inner">
                                  <div className="location-data-container">
                                    <div className="location-name">
                                      {continentItem.name_nn}
                                    </div>
                                    <div className="location-func-btns">
                                      {/* <button className="detail-btn">
                                      <AiOutlineInfoCircle />
                                    </button> */}

                                      <button
                                        className="remove-location"
                                        onClick={() => {
                                          SubmitDeleteLocationHandler(
                                            continentItem.id,
                                            1,
                                            continentItem.name_nn
                                          );
                                        }}
                                      >
                                        <AiOutlineMinusCircle />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-2 location-data-inner">
                                  <button
                                    className="add-location"
                                    onClick={() => {
                                      SubmitAddLocationHandler(
                                        1,
                                        continentItem.location_id
                                      );
                                    }}
                                  >
                                    Add Country{" "}
                                    <span>
                                      <AiFillPlusCircle />
                                    </span>
                                  </button>
                                </div>
                                {continentItem.sublocations.map(
                                  (countryItem, countryIndex) => {
                                    return (
                                      <div
                                        className={
                                          countryIndex <
                                            continentItem.sublocations.length - 1
                                            ? "row side-marker-country"
                                            : "row"
                                        }
                                      >
                                        <div className="col-4 curve-marker curve-marker-country">
                                          <div className="curve curve-country"></div>
                                        </div>
                                        <div className="col-2 location-data-inner">
                                          <div className="location-data-container">
                                            <div className="location-name">
                                              {countryItem.name_nn}
                                            </div>
                                            <div className="location-func-btns">
                                              {/* <button className="detail-btn">
                                            <AiOutlineInfoCircle />
                                          </button> */}

                                              <button
                                                className="remove-location"
                                                onClick={() => {
                                                  SubmitDeleteLocationHandler(
                                                    countryItem.id,
                                                    2,
                                                    countryItem.name_nn
                                                  );
                                                }}
                                              >
                                                <AiOutlineMinusCircle />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 location-data-inner">
                                          <button
                                            className="add-location"
                                            onClick={() => {
                                              SubmitAddLocationHandler(
                                                2,
                                                countryItem.location_id
                                              );
                                            }}
                                          >
                                            Add State{" "}
                                            <span>
                                              <AiFillPlusCircle />
                                            </span>
                                          </button>
                                        </div>
                                        {countryItem.sublocations.map(
                                          (stateItem, stateIndex) => {
                                            return (
                                              <div
                                                className={
                                                  stateIndex <
                                                    countryItem.sublocations
                                                      .length -
                                                    1
                                                    ? "row side-marker-state"
                                                    : "row"
                                                }
                                              >
                                                <div className="col-6 curve-marker curve-marker-state">
                                                  <div className="curve curve-state"></div>
                                                </div>
                                                <div className="col-2 location-data-inner">
                                                  <div className="location-data-container">
                                                    <div className="location-name">
                                                      {stateItem.name_nn}
                                                    </div>
                                                    <div className="location-func-btns">
                                                      {/* <button className="detail-btn">
                                                    <AiOutlineInfoCircle />
                                                  </button> */}

                                                      <button
                                                        className="remove-location"
                                                        onClick={() => {
                                                          SubmitDeleteLocationHandler(
                                                            stateItem.id,
                                                            3,
                                                            stateItem.name_nn
                                                          );
                                                        }}
                                                      >
                                                        <AiOutlineMinusCircle />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2 location-data-inner">
                                                  <button
                                                    className="add-location"
                                                    onClick={() => {
                                                      SubmitAddLocationHandler(
                                                        3,
                                                        stateItem.location_id
                                                      );
                                                    }}
                                                  >
                                                    Add City{" "}
                                                    <span>
                                                      <AiFillPlusCircle />
                                                    </span>
                                                  </button>
                                                </div>
                                                {stateItem.sublocations.map(
                                                  (cityItem, cityIndex) => {
                                                    return (
                                                      <div
                                                        className={
                                                          cityIndex <
                                                            stateItem.sublocations
                                                              .length -
                                                            1
                                                            ? "row side-marker-city"
                                                            : "row"
                                                        }
                                                      >
                                                        <div className="col-8 curve-marker curve-marker-city">
                                                          <div className="curve curve-city"></div>
                                                        </div>
                                                        <div className="col-2 location-data-inner">
                                                          <div className="location-data-container">
                                                            <div className="location-name">
                                                              {cityItem.name_nn}
                                                            </div>
                                                            <div className="location-func-btns">
                                                              {/* <button className="detail-btn">
                                                            <AiOutlineInfoCircle />
                                                          </button> */}

                                                              <button
                                                                className="remove-location"
                                                                onClick={() => {
                                                                  SubmitDeleteLocationHandler(
                                                                    cityItem.id,
                                                                    4,
                                                                    cityItem.name_nn
                                                                  );
                                                                }}
                                                              >
                                                                <AiOutlineMinusCircle />
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-2 location-data-inner">
                                                          <button
                                                            className="add-location"
                                                            onClick={() => {
                                                              SubmitAddLocationHandler(
                                                                4,
                                                                cityItem.location_id
                                                              );
                                                            }}
                                                          >
                                                            Add Local{" "}
                                                            <span>
                                                              <AiFillPlusCircle />
                                                            </span>
                                                          </button>
                                                        </div>
                                                        {cityItem.sublocations.map(
                                                          (
                                                            localItem,
                                                            localIndex
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  localIndex <
                                                                    cityItem
                                                                      .sublocations
                                                                      .length -
                                                                    1
                                                                    ? "row side-marker-local"
                                                                    : "row"
                                                                }
                                                              >
                                                                <div className="col-10 curve-marker curve-marker-local">
                                                                  <div className="curve curve-local"></div>
                                                                </div>
                                                                <div className="col-2 location-data-inner">
                                                                  <div className="location-data-container">
                                                                    <div className="location-name">
                                                                      {
                                                                        localItem.name_nn
                                                                      }
                                                                    </div>
                                                                    <div className="location-func-btns">
                                                                      {/* <button className="detail-btn">
                                                                    <AiOutlineInfoCircle />
                                                                  </button> */}

                                                                      <button
                                                                        className="remove-location"
                                                                        onClick={() => {
                                                                          SubmitDeleteLocationHandler(
                                                                            localItem.id,
                                                                            5,
                                                                            localItem.name_nn
                                                                          );
                                                                        }}
                                                                      >
                                                                        <AiOutlineMinusCircle />
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                  {localItem
                                                                    .branches
                                                                    .length <
                                                                    1 ? (
                                                                    <button
                                                                      className="add-branch-outer"
                                                                      onClick={() => {
                                                                        SubmitAddBranchHandler(
                                                                          localItem.location_id,
                                                                          localItem.name_nn

                                                                        );
                                                                      }}
                                                                    >
                                                                      <div className="add-branch-inner">
                                                                        Add
                                                                        Branch
                                                                      </div>
                                                                    </button>
                                                                  ) : (
                                                                    <div className="branches-outer">
                                                                      <div className="branches-heading">
                                                                        Branches
                                                                      </div>
                                                                      {localItem.branches.map(
                                                                        (
                                                                          item
                                                                        ) => {
                                                                          return (
                                                                            <button
                                                                              className="branch"
                                                                              onClick={() => {
                                                                                SubmitBranchDetailHandler(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="branch-name">
                                                                                {
                                                                                  item.branch_name_nn
                                                                                }
                                                                              </div>
                                                                            </button>
                                                                          );
                                                                        }
                                                                      )}
                                                                      <div className="add-branch-outer">
                                                                        <button
                                                                          className="add-branch-inner"
                                                                          onClick={() => {
                                                                            SubmitAddBranchHandler(
                                                                              localItem.location_id,
                                                                              localItem.name_nn
                                                                            );
                                                                          }}
                                                                        >
                                                                          Add
                                                                          Branch
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="nav-buttons-outer">
              <div></div>
              <button
                className="nav-btn next-btn"
                onClick={() => {
                  console.log("HErEr");
                  refetch_plugin()
                  setActiveKey("tab5");
                }}
              >
                {locationUpdateLoading && <div
                  style={{ marginRight: "10px" }}
                ><TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="#ffffff"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                  />
                </div>
                }
                <div>
                  {locationUpdateLoading ? "Saving" : "Save"}
                </div>
              </button>
            </div>
          </div>
        </Tab>

        <Tab eventKey="tab5" title="Tab 5">
          <div
            className={`tab-content ${activeKey === "tab5" ? "active-tab tab tab5" : "tab tab5"
              }`}
          >
            <div className="tab-heading">Add Members</div>
            <div className="organisation-name-outer">
              <div style={{ flexGrow: 1 }}>
                <div className="organisation-name-label-sub">
                  Please select one of the available plugins to start automated onboarding
                </div>
                <div>
                  <div>
                    {pluginData && pluginData.data.map((pluginData) => {
                      return <div className="plugin-outer"
                        onClick={() => { updatePluginHandler(pluginData.plugin_id) }}>
                        <div
                          className="plugin-inner"
                        >
                          <div className="plugin-image-outer"><img src={pluginData.logo} /></div>
                          <div className="plugin-name">{pluginData.name}</div>
                        </div>
                      </div>
                    })
                    }</div>
                </div>
              </div>
            </div>
            <div className="nav-buttons-outer">
              <button
                className="nav-btn back-btn"
                onClick={() => {
                  setActiveKey("tab4");
                }}
              >
                Back
              </button>
              <div></div>
            </div>
          </div>
        </Tab>
      </Tabs>
      <AddLocationPopup
        level={getLevelName(newLocationDirectoryIndex)}
        show={showAddLocationPopup}
        onSubmit={SubmitAddLocationPopupHandler}
        onClose={onAddLocationPopupClose}
      />
      <AddBranchPopup
        name={newBranchLocationName}
        show={showAddBranchPopup}
        onSubmit={SubmitAddBranchPopupHandler}
        onClose={onAddBranchPopupClose}
      />
      <BranchDetailPopup
        data={branchDetailsData}
        show={showBranchDetailsPopup}
        onSubmit={SubmitBranchDetailsPopupHandler}
        onClose={onBranchDetailsPopupClose}
      />
      <DeleteLocationPopup
        name={deleteLocationName}
        level={getLevelName(deleteLocationDirectoryIndex)}
        show={showDeleteLocationPopup}
        onSubmit={SubmitDeleteLocationPopupHandler}
        onClose={onDeleteLocationPopupClose}
      />
    </div>
  );
};

export default NewOrgansation;
