// apiSlice.js
import axios from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../features/auth/authSlice';

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    const token = api.getState().auth.token;

    // Add the token to the request if it exists
    if (token) {
        args.headers = { ...args.headers, Authorization: `Bearer ${token}` };
    }

    try {
        let result = await axiosInstance.request(args);

        // If unauthorized, attempt token refresh
        if (result.status === 403) {
            const refreshResult = await axiosInstance.get('/refresh');
            if (refreshResult.data) {
                const user = api.getState().auth.user;
                api.dispatch(setCredentials({ ...refreshResult.data, user }));

                // Retry the original query with the new token
                args.headers.Authorization = `Bearer ${refreshResult.data.token}`;
                result = await axiosInstance.request(args);
            } else {
                api.dispatch(logOut());
            }
        }

        return { data: result.data, meta: result.meta };
    } catch (error) {
        // Handle 401 Unauthorized
        if (error.response?.status === 401) {
            api.dispatch(logOut()); // Optionally log the user out
            window.location.href = '/login';
        }
        return { error: { status: error.response?.status, data: error.response?.data } };
    }
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({}),
});
